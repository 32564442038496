import React, { useState, useCallback, useEffect, useMemo } from 'react';
import MaterialTable, { MaterialTableProps, Column, Options, MTableCell } from 'material-table';
import MaterialIcons from 'src/assets/MaterialIcons';
import backendUser, { backendUserDTO } from 'src/interfaces/backendUser';
import { Redirect } from 'react-router-dom';
// import userRoles from 'src/enums/userRoles';
// }

interface IStateObject {
  columns: Column<object>[];
  data: backendUserDTO[];
  options?: Options;
}

// https://github.com/mbrn/material-table/blob/master/demo/demo.js
interface IProps {
  isAdmin: boolean;
  displayErrorMessage: (message: string) => void;
  materialTableCallback: any;
  mainQuery: () => Promise<any[]>;
  firstColumnModels: Column<object>[];
  title: string;
  deleteAction: (oldData: any) => Promise<any>;
  refresh: number;
  onClick: (event: any, rowData: any) => any;
}
interface ParamTypes {
  id: string;
}

const GenericCrudTable = ({
  isAdmin,
  displayErrorMessage,
  materialTableCallback,
  mainQuery,
  firstColumnModels,
  title,
  deleteAction,
  refresh,
  onClick,
}: IProps) => {
  const [state, setState] = useState<IStateObject>({
    columns: [...firstColumnModels],
    data: [],
  });

  const downloadJson = useCallback(async () => {
    try {
      const obtainedData = await mainQuery();
      console.log(obtainedData);
      if (!Array.isArray(obtainedData)) {
        throw 'wrong data';
      }

      setState({
        ...state,
        columns: [...firstColumnModels],
        data: obtainedData,
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    downloadJson();
  }, [downloadJson, refresh]);

  return (
    <>
      <MaterialTable
        tableRef={materialTableCallback}
        icons={MaterialIcons}
        title={title}
        columns={state.columns}
        data={state.data}
        // components={{
        //   Cell: (props) => <UserCellController {...props} />,
        //   EditField: (props) => <UserEditFieldController {...props} memoizedSellers={memoizedSellers} />,
        // }}
        actions={[
          {
            isFreeAction: false,
            icon: MaterialIcons.DetailPanel as any,
            tooltip: 'Zobacz projekt',
            onClick,

            // {
            //

            // },
          },
        ]}
        localization={{
          header: {
            actions: 'Edycja',
          },
          body: {
            editRow: {
              deleteText: 'archiwizuj zapytanie',
            },
          },
        }}
        options={{
          headerStyle: {
            backgroundColor: '#bb2727',
            color: '#FFF',
          },
          rowStyle: {
            backgroundColor: '#EEE',
          },
          columnsButton: true,
          draggable: false,
          pageSize: 10,
          pageSizeOptions: [10, 20, 40],
          emptyRowsWhenPaging: false,
        }}
        editable={{
          onRowDelete: deleteAction,
        }}
      />
    </>
  );
};

export default GenericCrudTable;
