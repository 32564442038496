import React from 'react';
import MaterialTable, { MaterialTableProps, Column, Options, MTableCell, Icons } from 'material-table';
import { pink } from '@material-ui/core/colors';
import userRoles from './../enums/userRoles';
import { Trans, useTranslation } from 'react-i18next';
import InquiryAccortion from 'src/components/InquiryAccortion';
import { backendUserDTO } from 'src/interfaces/backendUser';

interface IProps {
  value: string;
  columnDef: Column<any>;
  size: string;
  icons: Icons;
  rowData: backendUserDTO;
}

const UserCellController = (props: IProps) => {
  const { title } = props.columnDef;
  const { field } = props.columnDef;
  const { value } = props;
  const valueNumber = parseFloat(value);
  const { t, i18n } = useTranslation();

  if (field?.toString().includes('role') && !isNaN(valueNumber)) {
    return <MTableCell {...props} style={{verticalAlign: 'baseline', fontStyle: 'italic' }} value={t(userRoles[valueNumber])} />;
  }
  if (field?.toString().includes('inquiry')) {
    return (
      <MTableCell style={{padding: 0, verticalAlign: 'baseline'}} {...props} >
        <InquiryAccortion {...props}/>
      </MTableCell>
    );
    // InquiryAccortion
  }
  return <MTableCell {...props} style={{verticalAlign: 'baseline', fontWeight: !isNaN(value as any) && 'bold' }} />;
};

export default UserCellController;
