import React, { useCallback, useEffect, useState } from 'react';
import style from './LogOutButton.module.scss';
import ButtonTransparent from './ButtonTransparent';
import { useTranslation } from 'react-i18next';
import {
  AzureAD,
  IAzureADFunctionProps,
  AuthenticationState,
} from 'react-aad-msal';
import { authProvider } from 'src/authProvider';

const LogOutButton = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <AzureAD provider={authProvider}>
        {(AzureADFunctionProps: IAzureADFunctionProps) => {
          const {
            login,
            logout,
            authenticationState,
            error,
            accountInfo,
          } = AzureADFunctionProps;
          // console.log(accountInfo);
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              return (
                <ButtonTransparent
                  propStyle={{ padding: 0, marginRight: 0 }}
                  onClick={logout}
                >
                  <div className={style.logOutHolder}>
                    <span className={style.logOutText}>{t('log out')}</span>
                  </div>
                  <div className={style.logOutUserName}>
                    {accountInfo?.account?.userName}
                  </div>
                </ButtonTransparent>
              );
            case AuthenticationState.Unauthenticated:
              return (
                <ButtonTransparent
                propStyle={{ padding: 0, marginRight: 0 }}
                onClick={login}
              >
                <div className={style.logOutHolder}>
                  <span className={style.logOutText}>{t('log in')}</span>
                </div>
              </ButtonTransparent>
              );
            case AuthenticationState.InProgress:
              return null;
          }
        }}
      </AzureAD>
    </>
  );
};

export default LogOutButton;
