import { QuestionsAndAnswears } from './ContainerModel';
import { priceWithFixedDiggits } from 'src/utilities/numbersFormatter';

export interface IinquiryHeader {
  dateCreated?: string;
  docName?: string;
  title?: string;
  userName?: string;
  projectName?: string | null;
  userAAD?: string;
  humanReadableCode?: string;
  plnMultiplayer?: number;
  currencyUsed?: number;
}
export class InquiryModel implements IinquiryHeader {
  dateCreated?: string;
  docName?: string;
  fullJson!: InquiryDetails;
  jsonq!: QuestionsAndAnswears;
  title?: string;
  userName?: string;

  links!: string;
  projectName?: string | null;
  userAAD?: string;
  humanReadableCode?: string;
  serializable: object;
  plnMultiplayer!: number;
  currencyUsed?: number;
  [key: string]: any;

  constructor() {
    this.serializable = {
      fullJson: InquiryDetails,
      jsonq: QuestionsAndAnswears,
    };
  }
}

export class InquiryDetails {
  LacznaCena!: number;
  CenaObrobek!: number;
  LinkZapisu!: string;
  TypZapytania?: string;
  TypAplikacji?: string;
  Wysokosc!: number;
  TekstStandardowy?: string;
  TytulProjektu?: string;
  ListaKontenerow?: Kontener[];
  ElementyDodatkowe?: Kontener;
  ObrobkiDane?: Kontener;
  DaneOsob?: DaneOsobowe;

  serializable: object;
  [key: string]: any;

  constructor() {
    this.serializable = {
      ListaKontenerow: [Kontener],
      ElementyDodatkowe: Kontener,
      ObrobkiDane: Kontener,
    };
  }
}

export class Kontener {
  Nr!: string;
  Kondygnacja!: number;
  CenaBezZmian!: number;
  CenaZeZmianami!: number;
  Nazwa!: string;
  FNazwa!: string;
  ListaModyfikacji?: ZmianyWKontenerze[];
  getCenaZeZmianami = (currencyUsed:number, plnMultiplayer: number) =>
    this.CenaZeZmianami>0? priceWithFixedDiggits(this.CenaZeZmianami, currencyUsed, plnMultiplayer): "-";
}

export interface DaneOsobowe {
  Imie: string;
  Nazwisko: string;
  Email: string;
  Telefon: string;
  Firma: string;
}

export interface ZmianyWKontenerze {
  Ilosc: number;
  KosztZmiany: number;
  TypZmiany: string;
  //WyswietlaneLacznie: boolean;
}
