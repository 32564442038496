import React, { useState, useEffect, useRef, useCallback } from 'react';
// import logo from './logo.svg';
import stylez from './ProjectSaveView.module.scss';
import style from './StartingSchema.module.scss';
import 'src/components/firstArticle/FirstArticleSaved';
import { IContainersQueryShort, ContainersQuery } from 'src/models/ContainerModel';
import ISessionParams from 'src/interfaces/ISessionParams';
import IntegerSlider from 'src/components/IntegerSlider';
import { Trans, useTranslation } from 'react-i18next';
import { ConfigurationSchema } from 'src/models/ConfigurationSchema';
import { SliderData } from 'src/views/ProjectSaveView';
import SchemaSelector from 'src/components/SchemaSelector';
import 'src/components/firstArticle/FirstArticleBase.scss';
import SchemaSelectorCorridorButton from 'src/components/SchemaSelectorCorridorButton';
import SchemaSelectorButton from 'src/components/SchemaSelectorButton';
import ButtonFullfilled from 'src/components/ButtonFullfilled';
import { setMainSettings, obtainCurrentSettings } from 'src/utilities/obtainCurrentSettings';
import { useHistory, useLocation } from 'react-router-dom';

export interface ConfigurationSchemaDTO {
  rowsNumber: SliderData;
  columnsNumer: SliderData;
  containersNumber: SliderData;
}

interface IProps extends Partial<ISessionParams> {
  children?: React.ReactNode;
}

const MainView = (props: IProps) => {
  let history = useHistory();
  const search = useLocation().search;
  const [savesHeaders, setSavesHeaders] = useState<IContainersQueryShort[] | null>(null);
  //   const [selectedRawSave, setSelectedRawSave] = useState<ContainersQuery | null>(null); // change it onclick
  //   const [selectedProject, setSelectedProject] = useState<BasicProjectData | null>(null);
  const [configurationRaw, setConfigurationRaw] = useState<ConfigurationSchema>(new ConfigurationSchema());
  const [configuration, setConfiguration] = useState<ConfigurationSchemaDTO | null>(null);
  const { t, i18n } = useTranslation();
  // temporary - will be changed to webrequest
  const changeValue = useCallback(
    (next: Partial<ConfigurationSchema>) => {
      const target: ConfigurationSchema = { ...configurationRaw, ...next };
      setConfigurationRaw(target);
    },
    [configurationRaw],
  );
  const changeNumber = useCallback(
    (num: number) => {
      const targetNum: number = Math.min(num, configurationRaw.totalRow * configurationRaw.totalColumns);
      const rows = Math.min(Math.floor(targetNum / configurationRaw.totalColumns), configurationRaw.totalRow);
      const columns = Math.min(targetNum - rows * configurationRaw.totalColumns, configurationRaw.totalColumns);
      const target: ConfigurationSchema = { ...configurationRaw, rows, columns };
      setConfigurationRaw(target);
    },
    [configurationRaw],
  );
  const changeType = useCallback(
    (corridor: boolean) => {
      const apply = corridor ? configurationRaw.corridorSetup : configurationRaw.noCorridorSetup;
      const target: ConfigurationSchema = { ...configurationRaw, ...apply };
      setConfigurationRaw(target);
    },
    [configurationRaw],
  );

  useEffect(() => {
    if (history.location.pathname.includes('unlogged')) {
      console.log(history.location);
    }
  }, []);

  useEffect(() => {
    if (!configurationRaw) {
      console.log('NO QUERY ');
      return;
    }
    const tempVal: ConfigurationSchemaDTO =
      configurationRaw.noCorridorSetup.title === configurationRaw.title
        ? {
          columnsNumer: {
            displayText: 'columns number',
            minValue: 0,
            maxValue: configurationRaw.totalColumns,
            value: configurationRaw.columns + 1,
            setValue: (val) => changeNumber(configurationRaw.rows * configurationRaw.totalColumns + val - 1),
          },
          rowsNumber: {
            displayText: 'row number',
            minValue: 0,
            maxValue: configurationRaw.totalRow, // dynamic
            value: configurationRaw.rows + 1,
            setValue: (val) => changeNumber(configurationRaw.totalColumns * (val - 1) + configurationRaw.columns),
          },
          containersNumber: {
            displayText: 'total number',
            minValue: 0,
            maxValue: configurationRaw.totalColumns * configurationRaw.totalRow, // dynamic
            value: (configurationRaw.columns + 1) * (configurationRaw.rows + 1),
            setValue: (val) => changeNumber(val - 1),
          },
        }
        : {
          columnsNumer: {
            displayText: 'columns number',
            minValue: 0,
            maxValue: configurationRaw.totalColumns * 2,
            value: (configurationRaw.columns + 1) * 2,
            // setValue: (val) => changeNumber(configurationRaw.rows * configurationRaw.totalColumns + val - 1),
          },
          rowsNumber: {
            displayText: 'row number',
            minValue: 0,
            maxValue: configurationRaw.totalRow * 3, // dynamic
            value: (configurationRaw.rows + 1) * 3,
            // setValue: (val) => changeNumber(configurationRaw.totalColumns * (val - 1) + configurationRaw.columns),
          },
          containersNumber: {
            displayText: 'total number',
            minValue: 0,
            maxValue: configurationRaw.totalColumns * configurationRaw.totalRow * 6, // dynamic
            value: (configurationRaw.columns + 1) * (configurationRaw.rows + 1) * 6,
            setValue: (val) => changeNumber(val - 1),
          },
        };
    setConfiguration(tempVal);
  }, [configurationRaw, t]);

  return (
    <div className={stylez.main__view}>
      {props.children}
      <svg
        style={{ width: '100%', height: '100%' }}
        className={stylez.triangleCover}
        viewBox="0 0 1000 1000"
        preserveAspectRatio="none"
      >
        <polygon points="0,0 500,0 1000,1000 0,1000" fill="white" />
      </svg>
      <div className={style.holder}>
        <div className={`FirstArticleHolderSliders ${style.slidersHolder}`}>
          <ButtonFullfilled
            onClick={() => {
              changeType(false);
            }}
            propClass={`${style.smallButton} ${configurationRaw.corridor ? style.selectedButton : ''}`}
          >
            {t(configurationRaw.noCorridorSetup.title)}
          </ButtonFullfilled>
          <ButtonFullfilled
            onClick={() => {
              changeType(true);
            }}
            propClass={`${style.smallButton} ${!configurationRaw.corridor ? style.selectedButton : ''}`}
          >
            {t(configurationRaw.corridorSetup.title)}
          </ButtonFullfilled>
          <ButtonFullfilled
            onClick={() => {
              const apply = configurationRaw.noCorridorSetup;
              changeValue({ ...apply, totalColumns: 0, totalRow: 0 });
            }}
            propClass={`${style.smallButton} ${style.selectedButton}`}
          >
            {t('individual project')}
          </ButtonFullfilled>
          {configuration &&
            Object.values(configuration)
              .filter((elem) => typeof elem === 'object')
              .map((elem: SliderData) => (
                <IntegerSlider
                  key={elem.displayText}
                  displayText={t(elem.displayText)}
                  min={elem.minValue}
                  max={elem.maxValue} // dynamic
                  value={elem.value}
                  setValue={elem.setValue}
                />
              ))}
        </div>
        <SchemaSelector
          onConfirm={() => {
            setMainSettings(configurationRaw.getDtoValue.call(configurationRaw));
            if (history.location.pathname.includes('unlogged')) {
              history.push(`/unloggedUnity${search}`);
            }
            else {
              history.push(`/project${search}`);
            }
          }}
          headerText={t(configurationRaw.title)}
          startButtonText={
            configurationRaw.rows < 0 || configurationRaw.columns < 0
              ? t('start empty')
              : t('insert selected containers')
          }
          confSchema={configurationRaw}
          changeNumber={changeNumber}
        >
          {configurationRaw?.corridor ? <SchemaSelectorCorridorButton /> : <SchemaSelectorButton />}
        </SchemaSelector>

        <div className={style.SecArticleTitle}>
          <div>
            <p>{t('new')}</p>
            <p>{t('project')}</p>
          </div>
          <div className={style.redBox} />
        </div>
      </div>
    </div>
  );
};

export default MainView;
