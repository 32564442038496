import React from 'react';
import './SecArticleBase.scss';
import style from './SecArticleSaved.module.scss';
import { Trans, useTranslation } from 'react-i18next';
// import logo from 'src/assets/images/modular-logo-white.png';
import { ReactComponent as LogoModular } from '../../assets/images/MODULAR_logo.svg';
import ButtonTransparent from 'src/components/ButtonTransparent';
import { BasicProjectData, SliderData } from 'src/views/ProjectSaveView';
import { Typography, Paper, Button } from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import { host, save_project } from 'src/models/urlEndpoints';
import backendUser, { IBackendUserWithSaves } from 'src/interfaces/backendUser';

interface IProps {
  selectedProject: BasicProjectData | null;
  backendUser: backendUser | null | undefined;
}
//adress=https://modularsystem.com.pl/upload/zapisy/tgk_jan_grochala/153108-08-20-13-51-46.csv&change"
const SecArticleSettup = (props: IProps) => {
  const { t, i18n } = useTranslation();
  const dateS = props.selectedProject?.date ? new Date(props.selectedProject?.date).toLocaleString('pl-pl') : '';
  return (
    <article className={`SecArticleBase ${style.SecArticleHolder}`}>
      <div>
        <NavLink
          className={style.redButtonLink}
          to={`/project?adress=${host}/${save_project(
            props.selectedProject?.docName as string,
            props.selectedProject?.userAAD as string,
          )}`}
        >
          <Button className={style.redButton}><span>
            {t('load')}
          </span>
          </Button>
        </NavLink>
        {props?.backendUser && props.selectedProject &&
          props.selectedProject?.userAAD !== props?.backendUser?.idAAD && (<NavLink
            className={style.redButtonLink}
            to={`/project?adress=${host}/${save_project(
              props.selectedProject?.docName as string,
              props.selectedProject?.userAAD as string,
            )}&override=${props.selectedProject.userAAD}`}
          >
            <Button className={`${style.redButton} ${style.blackButton}`}>{`${t('load')}\n`}
              <span className={style.smallText}>
                {t('in the name of client')}
              </span>
            </Button>
          </NavLink>)}
      </div>
      <div className={style.imageHolder}>
        <img className={style.image} src={props.selectedProject?.imageUrl} />
      </div>
      <div className={style.SecArticleTitle}>
        <div>
          <p>{props.selectedProject?.title}</p>
          <p style={{ fontSize: '0.5em' }}>{dateS}</p>
        </div>
        <div className={style.redBox} />
      </div>
    </article>
  );
};

export default SecArticleSettup;
