import React from 'react';
import './FirstArticleBase.scss';
import './FirstArticleLogin.scss';
import { useTranslation } from 'react-i18next';

interface IProps {}

const FirstloginArticle = (props: IProps) => {
  const { t, i18n } = useTranslation();
  return (
    <article className="FirstArticleBase FirstArticlelogin">
      <h1>{t('login')}</h1>
      <h2>{t('configurator')}</h2>
      <h3>{t('of containers')}</h3>

    </article>
  );
};

export default FirstloginArticle;
