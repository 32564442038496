import React, { useState, useEffect } from 'react';
import { ConfigurationSchema } from 'src/models/ConfigurationSchema';
import style from './SchemaSelector.module.scss';
// import logo from './logo.svg';
import ButtonFullfilled from 'src/components/ButtonFullfilled';

interface IProps {
  confSchema: ConfigurationSchema;
  changeNumber: (num: number) => void;
  children?: React.ReactNode;
  headerText: string;
  startButtonText: string;
  onConfirm: () => void;
}

const SchemaSelector = (props: IProps) => {
  const [arr, setArr] = useState<Array<Array<number>>>([]);
  const selectedId = props.confSchema.totalColumns * props.confSchema.rows + props.confSchema.columns;
  useEffect(() => {
    const tempArr = [];
    for (let i = 0; i < props.confSchema.totalRow; i++) {
      const arrLow: Array<number> = [];
      for (let r = 0; r < props.confSchema.totalColumns; r++) {
        arrLow.push(props.confSchema.totalColumns * i + r);
      }
      tempArr.push(arrLow);
    }
    setArr(tempArr);
  }, [props.confSchema.totalRow]);

  return (
    <div className={style.smallCointainer}>
      <p>{props.headerText}</p>
      {arr.map((colArray, rowIndex) => {
        return (
          <div key={Math.random().toString()} className={style.row}>
            {colArray.map((num, colInder) => {
              return (
                <button
                  key={Math.random().toString()}
                  onClick={() => {
                    props.changeNumber(num);
                  }}
                  style={selectedId === num ? { backgroundColor: '#b01116' } : {}}
                  className={`${style.btn} ${props.confSchema.rows >= rowIndex && props.confSchema.columns >= colInder? style.selectedButton : ''}`}
                >
                  {props.children}
                  {/* {num + 1} */}
                </button>
              );
            })}
          </div>
        );
      })}
      <ButtonFullfilled onClick={props.onConfirm} propStyle={{ margin: '2em auto 0' }}>
        {props.startButtonText}
      </ButtonFullfilled>
    </div>
  );
};
export default SchemaSelector;
