import React, { useCallback } from 'react';
import './FirstArticleBase.scss';
import './FirstArticleMain.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// import logo from 'src/assets/images/modular-logo-white.png';
import { ReactComponent as LogoModular } from '../../assets/images/MODULAR_logo.svg';
import ButtonTransparent from 'src/components/ButtonTransparent';

interface IProps {}

const FirstArticleMain = (props: IProps) => {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const desingClick = useCallback(() => {
    history.push('/setup');
  }, [history]);
  const savedClick = useCallback(() => {
    history.push('/saves');
  }, [history]);
  return (
    <article className="FirstArticleBase FirstArticleMain">
      {/* modular-logo-white.png */}
      <LogoModular className="FirstArticleBaseLogo" color="white" />
      <div className="FirstArticleButtonHolder">
        <ButtonTransparent
          onClick={desingClick}
          propStyle={{ border: '3px solid white' }}
        >
          <span>{t('make project')}</span>
          {/* <h1>{t('login')}</h1> */}
        </ButtonTransparent>
        <ButtonTransparent
          onClick={savedClick}
          propStyle={{ border: '3px solid white' }}
        >
          <span className="FirstArticleSmallerButton">
            {t('saved')} <br /> {t('projects')}
          </span>
        </ButtonTransparent>
      </div>
    </article>
  );
};

export default FirstArticleMain;
