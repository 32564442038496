import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { host, setupDatasAll } from 'src/models/urlEndpoints';
import { fetchProtectedRawUnsafe } from 'src/utilities/fetchProtected';
import { IncomingHttpHeaders } from 'http2';
interface IProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: Object[];
  downloadPath: string;
  displayErrorMessage: (message: string) => void;
}

export default function AlertDialog({ modalOpen, setModalOpen, data, downloadPath, displayErrorMessage }: IProps) {
  const handleClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);
  const encode = useCallback((data: object[]): string => {
    console.log(data);
    const parsedData = data.reduce((prevValue, currValueOld, currIndex) => {
      // const nval = {...currValue}
      const firstRow = data[0];
      let currValue = currValueOld; 
      if (data.length > 1 && Object.keys(currValue).length !== Object.keys(firstRow).length) {
        currValue =  {...firstRow}
        const firstkeys = Object.keys(currValue);
        for (let i = firstkeys.length - 1; i >= 0; i--) {
          const keyName = firstkeys[i]
          if (currValueOld.hasOwnProperty(keyName)) {
            (currValue as any)[keyName] = (currValueOld as any)[keyName]
          } else {
            (currValue as any)[keyName] = ""
          }
        }
      }
      // const values = Object.values(currValue);
      // for (let i = values.length - 1; i >= 0; i--) {
      //   const val = values[i];
      //   if (typeof val !== "string") {
      //     continue;
      //   }
      //   const splitVal = val.split("_");
      //   if (splitVal.length !== 2) {
      //     continue;
      //   }
      //   const last = splitVal[1];
      //   if (typeof last === "undefined") {
      //     continue;
      //   }
      //   const num = parseInt(last)
      //   if (lastnum - num > 1) {
      //     const entries = Object.entries(prevValue);
      //     for (let r = 0; r < lastnum - num; r++) {
      //       const key = `${splitVal[0]}_${num + r + 1}`;
      //       entries.splice(i + r + 1, 0, [key, ""]);
      //     }
      //     currValue = Object.fromEntries(entries);
      //     console.log("x", currValue);
      //   }
      //   lastnum = num;
      // }
      return `${prevValue}${Object.values(currValue)
        .filter((elem) => typeof elem !== 'object')
        .reduce(
          (prevVal, currVal, currId) =>
            `${prevVal}${typeof currVal !== 'object'
              ? currVal +
              (currId === Object.values(currValue).length - 2 ? '' : ';') // error
              : ';'
            }`,
          '',
        )}${currIndex !== data.length - 1 ? `\n` : ''}`;
    }, '');
    // console.log(parsedData);
    return parsedData;
  }, []);

  const sendData = useCallback(
    async (csvText: string) => {
      const jsonData = {
        payload: csvText,
      };
      // const formData = new FormData();
      // formData.append('filename', downloadPath as string);
      // formData.append('csvdata', csvText as string);

      try {
        const resp = await fetchProtectedRawUnsafe(`${host}/${setupDatasAll(downloadPath)}`, {
          method: 'PUT',
          body: JSON.stringify(jsonData),
          headers: {
            'content-type': 'application/json',
          },
        });
        console.log(resp);
        if (resp.ok) {
          displayErrorMessage('Dane zapisane!');
          handleClose();
        } else if (resp.status === 403) {
          displayErrorMessage('zaloguj się');
        } else if (resp.status === 400) {
          displayErrorMessage('niewłaściwe dane zgłoś do dewelopera');
        } else {
          displayErrorMessage('wystapił błąd, sprawdź poprawność działania i zgłoś do dewelopera');
        }
        // const responseJson = await resp.json();
        // console.log(resp);
        // console.log(responseJson);
      } catch (e) {
        displayErrorMessage('wystapił błąd, sprawdź poprawność działania i zgłoś do dewelopera');
      }
      // ustalenie stanu - jakiś callback
    },
    [displayErrorMessage, downloadPath, handleClose],
  );

  let encodedData = '';
  if (modalOpen) {
    encodedData = encode(data);
  }
  const saveAll = () => {
    const csvData = encode(data);
    sendData(csvData);
  };

  return (
    <div>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Zapis poniższych danych</DialogTitle>
        <DialogContent>
          <p style={{ fontSize: '15px', whiteSpace: 'pre-line' }} id="alert-dialog-description">
            {/* Czy zapisać dane z bieżącej zakładki? */}
            {encodedData}
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Odrzuć
          </Button>
          <Button onClick={saveAll} color="primary" autoFocus>
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
