import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { Column } from 'material-table';

import { red } from '@material-ui/core/colors';
import ISessionParams from 'src/interfaces/ISessionParams';
import UserTable from 'src/views/UserTable';
import userRoles from 'src/enums/userRoles';
import currencyType from 'src/enums/currencyType';
import { host, usersAll, usersMy, userEndpoint } from 'src/models/urlEndpoints';
import backendUser, { backendUserDTO } from 'src/interfaces/backendUser';
import fetchProtectedJson from 'src/utilities/fetchProtected';
import { useTranslation } from 'react-i18next';
interface IProps extends Partial<ISessionParams> {}
const firstColumnModels = (role: userRoles, t: (s: string) => string): Column<object>[] => {
  if (role < userRoles.seller) {
    return [
      {
        title: t('inquiries'),
        field: 'inquiry',
        editable: 'never',
      },
    ];
  }
  const admin = role >= userRoles.administrator;
  const rolesLockup = Object.fromEntries(Object.entries(userRoles).filter(elem=>Number.parseFloat(elem[0])));
  const currencyLockup = Object.fromEntries(Object.entries(currencyType).filter(elem=>Number.parseFloat(elem[0])));
  const defaultFilter = ["1000", "2000", "3000", "4000", "5000"];
  currencyLockup.null = "PLN"
  currencyLockup[0] = "PLN"
console.log(currencyLockup);
  return [
    {
      title: 'Id',
      field: 'id',
      type: 'numeric',
      editable: 'never',
      filtering: false,
    },
    {
      title: t('first name'),
      field: 'firstName',
      editable: 'never',
      filtering: false,
    },
    {
      title: t('last name'),
      field: 'lastName',
      editable: 'never',
      tooltip: 'edycja w AAD',
      filtering: false,
    },
    {
      title: t('company'),
      field: 'company',
      editable: 'never',
      tooltip: 'edycja w AAD',
      // filtering: false,
    },
    {
      title: 'Email',
      field: 'email',
      editable: 'never',
      tooltip: 'edycja w AAD',
      filtering: false,
    },
    {
      title: t('role'),
      field: 'role',
      editable: admin ? 'always' : 'never',
      lookup: rolesLockup,
      defaultFilter : defaultFilter,
      headerStyle: {maxWidth: "100px", width: "100px"},
      cellStyle: {maxWidth: "100px", width: "100px"},
      filterCellStyle: {maxWidth: "130px", width: "130px"},
    },
    {
      type: 'numeric',
      title: t('discount') + '%',
      field: 'discount',
      editable: admin ? 'always' : 'never',
      tooltip: 'rabat w procentach',
      filtering: false,
    },
    {
      title: t('trade supervisor'),
      field: 'tradeSupervisor',
      editable: admin ? 'always' : 'never',
      filtering: false,
    },
    {
      title: t('inquiries'),
      field: 'inquiry',
      editable: 'never',
      filtering: false,
    },
    {
      title: t('Currency'),
      field: 'currencyUsed',
      tooltip: 'rabat w procentach',
      lookup: currencyLockup,
      editable: admin ? 'always' : 'never',
      filtering: false,
    },
    {
      title: t('PriceGroup'),
      field: 'companyGroup',
      tooltip: 'grupa cenowa',
      editable: admin ? 'always' : 'never',
      filtering: false,
    }
  ];
};
const adminUsersPromise = async (): Promise<backendUser[]> => {
  const usersJson: backendUser[] = await fetchProtectedJson(`${host}/${usersAll}`); // /user/myCustomers
  return usersJson;
};
const sellerUsersPromise = async (): Promise<backendUser[]> => {
  const meJson: backendUser = await fetchProtectedJson(`${host}/${userEndpoint}`); // /user/myCustomers
  const usersJson: backendUser[] = await fetchProtectedJson(`${host}/${usersMy}`); // /user/myCustomers
  return [meJson, ...usersJson];
};
const customerUsersPromise = async (): Promise<backendUser[]> => {
  const meJson: backendUser = await fetchProtectedJson(`${host}/${userEndpoint}`); // /user/myCustomers
  return [meJson];
};

function SettingView(props: IProps) {
  const { t, i18n } = useTranslation();
  const displayErrorMessage = useCallback((message: string) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  }, []);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('Start');
  const materialTableCallback: any = useRef(null);

  const api_regex = /^\/api\/.*/;
  const development: boolean = process.env.NODE_ENV === 'development';
  const currentRole = props?.backendUser?.role as number;
  const isAdmin = (props?.backendUser?.role as number) > userRoles.seller;
  const [lastLang, setLastLang] = useState(i18n.language);
  useEffect(() =>{
    setLastLang(i18n.language);
  }, [i18n.language])

  if (api_regex.test(window.location.pathname)) {
    return <div />;
  }
  
  //   const handleClick = (e: { preventDefault: () => void }) => {
  //     const stateValue = materialTableCallback.current?.state?.lastEditingRow;
  //     console.log(stateValue);
  //     if (stateValue) {
  //       e.preventDefault();
  //     }
  //   };
  return (
    <>
      <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} autoHideDuration={3500}>
        <SnackbarContent
          style={{
            backgroundColor: red[900],
          }}
          message={snackbarMessage}
        />
      </Snackbar>
      {i18n.language === lastLang && (
        <UserTable
          isAdmin={isAdmin}
          displayErrorMessage={displayErrorMessage}
          materialTableCallback={materialTableCallback}
          usersQuery={
            currentRole >= userRoles.administrator
              ? adminUsersPromise
              : currentRole >= userRoles.seller
              ? sellerUsersPromise
              : customerUsersPromise
          }
          filtering={true}
          firstColumnModels={firstColumnModels(props?.backendUser?.role as number, t)}
        />
      )}
    </>
  );
}

export default SettingView;
