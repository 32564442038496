
//https://localhost:3001/model/test?url=https://localhost:5012/project/save/fe41f984-1f4d-457f-a00a-f046c7fc55e4/a4f5a2f2-52c9-4ad0-8ca5-e4ed478558cc
import React, { useCallback, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import SingleContainer from './../components/containersConfigurations/SingleContainer';
import { ContainerModel, Containers } from '../models/ContainerModel';
import DeserializeToObject from '../utilities/DeserializeToObject';
import { Paper, Typography, Container } from '@material-ui/core';
import ContainerImageSchema from './../components/containersConfigurations/ContainerImageSchema';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const development: boolean = process.env.NODE_ENV === 'development';
const ContainersConfiguration = () => {
  let { id } = useParams();
  let query = useQuery();

  const [containers, setContainers] = useState<Containers | undefined>(
    undefined,
  );

  const downloadCsv = useCallback(async () => {
    const urlAdress = query.get('url') || '';
    try {
      const resp = await fetch(urlAdress);
      const text = await resp.text();
      const constainersQuery = JSON.parse(text);
      const constainersModel = JSON.parse(constainersQuery.payload);
      const transformedContainers: Containers = DeserializeToObject(
        new Containers(),
        constainersModel,
      );
      setContainers(transformedContainers);
      console.log(transformedContainers);
    } catch (e) {
      console.log(e);
      console.error(e);
    }
  }, []);

  useEffect(() => {
    downloadCsv();
  }, [downloadCsv]);
  if (!containers) {
    return <Typography variant="h3">wczytywanie</Typography>;
  }
  return (
    <div className="mainContainer">
      <Container className="summaryContainer" maxWidth="lg">
        <Paper elevation={2} className="logoContainer">
          <div>
            <Typography>Zestawienie kontenerów</Typography>
          </div>
        </Paper>
        <Paper elevation={2} className="basicTextHolder">
          <Typography className="summarySubtitle">
            Informacje podstawowe
          </Typography>
          {(containers?.ProjectTitle?.length ?? false) > 0 && (
            <Typography variant="h1">
              {`Nazwa projektu: ${containers.ProjectTitle}`}
            </Typography>
          )}
          <Typography variant="h2">
            {(containers?.UUID?.length ?? false) &&
              ` Kod projektu ${containers.UUID}`}
            {(containers?.SaveTime?.length ?? false) &&
              ` Projekt zapisany ${containers.SaveTime}`}
          </Typography>
          <Typography>
            Projekt zapisany pod ścieżką: {query.get('url')}
          </Typography>
        </Paper>
        {containers.containers.map((containerModel) => (
          <SingleContainer
            key={containerModel.Nr.toString()}
            containerModel={containerModel}
            containers={containers}
          />
        ))}
        {/* <p>{displayJson}</p> */}
        <ContainerImageSchema />
      </Container>
    </div>
  );
};
export default ContainersConfiguration;
