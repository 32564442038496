import React from 'react';
import MaterialTable, {
  MaterialTableProps,
  Column,
  Options,
  MTableCell,
  Icons,
} from 'material-table';
import { pink } from '@material-ui/core/colors';

interface IProps {
  value: string;
  columnDef: Column<any>;
  size: string;
  icons: Icons;
  rowData: any;
}

const CellController = (props: IProps) => {
  const { title } = props.columnDef;
  const { value } = props;
  const valueNumber = parseFloat(value);
  if (typeof value === 'boolean') {
    const columnDef = { ...props.columnDef, type: 'boolean' };
    return <MTableCell {...props} columnDef={columnDef} />;
  }
  if (
    title?.toString().includes('Cena') &&
    (!value || valueNumber === 0 || valueNumber > 900000)
  ) {
    return (
      <MTableCell
        {...props}
        style={{ fontStyle: 'italic' }}
        value="niedostępny"
      />
    );
  }
  if (valueNumber === -1) {
    return (
      <MTableCell
        {...props}
        style={{ fontStyle: 'italic' }}
        value="Cena Podstawowa"
      />
    );
  }
  if (valueNumber === -2) {
    return (
      <MTableCell
        {...props}
        style={{ fontStyle: 'italic' }}
        value="Cena Mobilbox"
      />
    );
  }
  return (
    <MTableCell
      {...props}
      style={!isNaN(value as any) && { fontWeight: 'bold' }}
    />
  );
};

export default CellController;
