import React, { useCallback, useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ContainerModel, Containers } from '../../models/ContainerModel';
import {
  containerElement,
  containerElementsFactory,
} from '../../controllers/containersConfigs/elementsFactory';

interface IProps {
  containerModel: ContainerModel;
  containers: Containers;
}

function createData(
  id: string,
  operation: string,
  variantShort: string,
  comments: string,
) {
  return { id, operation, variantShort, comments };
}

const rows = [
  createData('MB', 'operacja 1', 'MB', ''),
  createData('MB2', 'operacja 2', 'MB2', ''),
];

const SingleContainer = ({ containerModel, containers }: IProps) => {
  // return <p>{containerModel.ID}</p>;

  const [containerElements, setContainerElements] = useState<
    containerElement[]
  >([]);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel: string) => (
    _event: any,
    isExpanded: React.SetStateAction<boolean>,
  ) => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    if (expanded && containerElements.length === 0) {
      setContainerElements(
        containerElementsFactory({ containerModel, containers }),
      );
    }
  }, [expanded]);
  // console.log(containerElements);
  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange('panel1')}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>
          Kontener numer: {containerModel.Nr}, Nazwa kontenera:{' '}
          {containerModel.GetCurrentVariant().Title}
          {(containers?.UUID?.length ?? false) &&
            `, unikalny kod kontenera ${containerModel.UUID}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className="container-table-holder">
        <Typography>Szczegółowe dane na temat kontenera</Typography>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow className="container-tablerow">
              <TableCell></TableCell>
              <TableCell align="right">Operacja</TableCell>
              <TableCell align="right">Wariant&nbsp;Panelu</TableCell>
              <TableCell align="right">Uwagi</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {containerElements.map((CE) => (
              <TableRow key={CE.id} className="container-tablerow">
                <TableCell component="th" scope="row">
                  {CE.id}
                </TableCell>
                <TableCell align="right">{CE.operation}</TableCell>
                <TableCell align="right">{CE.variantShort}</TableCell>
                <TableCell align="right">{CE.comments}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};

export default SingleContainer;
