import React, { useCallback, useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Paper, Button } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { Column, Icons } from 'material-table';
import { IinquiryHeader, InquiryModel, InquiryDetails } from 'src/models/InquiryModel';
import style from './InquiryAccortion.module.scss';

import { host, inquiryHeader } from 'src/models/urlEndpoints';
import fetchProtectedJson from 'src/utilities/fetchProtected';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { backendUserDTO } from 'src/interfaces/backendUser';
import { priceWithFixedDiggits } from 'src/utilities/numbersFormatter';
import { red } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
interface IProps {
  value: string;
  columnDef: Column<any>;
  size: string;
  icons: Icons;
  rowData: backendUserDTO;
  expendedAtStart?: boolean;
}

const InquiryAccortion = ({ rowData, expendedAtStart }: IProps) => {
  const { t } = useTranslation();
  let history = useHistory();
  const [expanded, setExpanded] = useState(expendedAtStart ? true : false);
  const [inquires, setInquires] = useState<InquiryModel[] | null>(null);
  // 10 ostatnich zapytan
  const handleChange = (panel: string) => (_event: any, isExpanded: React.SetStateAction<boolean>) => {
    setExpanded(isExpanded ? true : false);
  };
  const downloadHeaders = useCallback(
    async (query: string) => {
      const resp: InquiryModel[] = await fetchProtectedJson(`${host}/${inquiryHeader}${query}`);

      resp.forEach((s) => {
        s.fullJson = s.fullJson ? JSON.parse((s.fullJson as unknown) as string) : null;
      });
      // sort
      resp.sort((a, b) => new Date(b.dateCreated as string).getTime() - new Date(a.dateCreated as string).getTime());
      if (resp) {
        setInquires(resp);
      }
    },
    [setInquires],
  );
  useEffect(() => {
    const email = rowData.email;

    downloadHeaders(`?user=${email}`);
  }, []);

  useEffect(() => {
    if (expanded) {
      const email = rowData.email;
      downloadHeaders(`?user=${email}&fullData=true`);
    }
  }, [expanded]);
  const navTo = useCallback(
    (url: string) => {
      history.push(url);
    },
    [history],
  );

  //   console.log(inquires);
  // console.log(containerElements); C:\Projekty\modularplatform\Modular.Platform\src\Modular.Platform.ClientApp\src\components\InquiryAccortion.tsx
  const slicedInquired = inquires?.slice(0, Math.min(inquires.length, 15));
  return (
    <Accordion
      className={style.cell}
      expanded={expanded}
      onChange={handleChange('panel1')}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography component="h5"> {t('inquiries')}: {inquires?.length}</Typography>
      </AccordionSummary>
      <AccordionDetails className={style.accordion_details}>
        <Button style={{backgroundColor: red[700]}} variant="contained" >
          <Link style={{justifyContent: 'center'}} className={style.link} to={`/inquires/${rowData.email}`}>
            <Typography className={style.button_display} component="h6">
              {t('see all inquires')}
            </Typography>
          </Link>
        </Button>
        {slicedInquired?.map((elem: InquiryModel, index: number) => {
          return (
            <Button
              key={elem.docName}
              // onClick={() => navTo(`/inquiry/${elem.docName}/${elem.userAAD}`)}
            >
              <Link className={style.link} to={`/inquiry/${elem.docName}/${elem.userAAD}`}>
                <Paper className={style.paper} elevation={1}>
                  <Typography className={style.inquiry_header} component="h4">
                  {t('no')}: {index + 1}
                  </Typography>
                  <Typography className={style.inquiry_text} component="h6">
                    {t('project code')}: {elem.humanReadableCode ?? 'brak kodu'}
                  </Typography>
                  {elem.projectName && (
                    <Typography className={style.inquiry_text} component="h6">
                      {t('project name')}Nazwa projektu: {elem.projectName}
                    </Typography>
                  )}
                  <Typography className={style.inquiry_text} component="h6">
                    {t('price')}: {elem.fullJson && priceWithFixedDiggits(elem.fullJson.LacznaCena, elem.currencyUsed as number, elem.plnMultiplayer)}
                  </Typography>
                  <Typography className={style.inquiry_text} component="h6">
                    {t('date send')}: {new Date(elem.dateCreated as string).toLocaleString('pl-pl')}
                  </Typography>
                </Paper>
              </Link>
            </Button>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
InquiryAccortion.defaultProps = {
  isExpanded: false,
};

export default InquiryAccortion;
