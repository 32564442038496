import React from 'react';
// import logo from './logo.svg';
import './MainStaticContainer.scss';
interface IProps {
  children: React.ReactNode;
}

const MainStaticContainer = (props: IProps) => {
  return (
    <>
      <div
        className="main__container"
        // style={{ backgroundImage: `url(${props.background})`, backgroundColor }}
      >
        {props.children}
      </div>
    </>
  );
};
export default MainStaticContainer;
