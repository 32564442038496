import React, { useCallback } from 'react';
import style from './LanguageController.module.scss';
import ButtonTransparent from './ButtonTransparent';
import { useTranslation } from 'react-i18next';
import { languages } from 'src/i18/i18n';
interface IProps {
  //   children: React.ReactNode;
}

const LanguageController = (props: IProps) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
    },
    [i18n],
  );

  return (
    <>
      {languages.map((lang) => (
        <ButtonTransparent key={lang} onClick={() => changeLanguage(lang)}>
          <span
            className={`${style.standardLang} ${
              lang === i18n.language ? style.highlightLang : ''
            }`}
          >
            {lang}
          </span>
        </ButtonTransparent>
      ))}
    </>
  );
};

export default LanguageController;
