import React, { useState, useEffect, useRef, useCallback, Children, useMemo } from 'react';
import { Typography, Paper, Button, Backdrop } from '@material-ui/core';
import style from './ArticleSavedScroll.module.scss';
import './ArticleSavedScroll.scss';
import { storage } from 'src/models/urlEndpoints';
import { BasicProjectData } from 'src/views/ProjectSaveView';
import { useTranslation } from 'react-i18next';
import ArticleSavedScrollSelection from 'src/components/ArticleSavedScrollSelection';
import backendUser, { IBackendUserWithSaves } from 'src/interfaces/backendUser';
import userRoles from 'src/enums/userRoles';
import fetchProtectedJson from 'src/utilities/fetchProtected';
import { host, usersMy, save_header, save_project } from 'src/models/urlEndpoints';
import { IContainersQueryShort, ContainersQuery } from 'src/models/ContainerModel';
import DeserializeToObject from 'src/utilities/DeserializeToObject';
interface IPropsImage {
  value: IContainersQueryShort;
  selectProject: (a: string) => void;
  selectedProject: BasicProjectData | null;
  index: number;
  totalNumber: number;
  uuid: string;
}


const ImageElement = ({ value, selectProject, selectedProject, index, totalNumber, uuid }: IPropsImage) => {
  const imgRef = useRef<HTMLImageElement>(null);
  return (
    <Paper key={value.docName} onClick={() => selectProject(uuid)} elevation={2} className={style.imageHolder}>
      {/* <div > */}
      <p>{value.title}</p>
      {/* <div className={style.image} style={{backgroundImage: `url(${storage}/${value.imageUrl})`}}></div> */}
      <img
        style={imgRef.current ? { width: imgRef.current?.width } : {}}
        ref={imgRef}
        className={style.image}
        src={`${storage}/${value.imageUrl}`}
      ></img>
      <p className={style.bottomDate}>{new Date(value.saveTime).toLocaleString("pl-PL")} {value.projectName}</p>
      {/* </div> */}
      {selectedProject?.docName === value.docName && (
        <div className={style.selected}>
          <span>
            {index + 1}/{totalNumber}
          </span>
        </div>
      )}
    </Paper>
  );
};

interface IProps {
  headers: IContainersQueryShort[] | null;
  selectedProject: BasicProjectData | null;
  backendUser: backendUser | null | undefined;
  setSelectedRawSave: React.Dispatch<React.SetStateAction<ContainersQuery | null>>
}
// const getUserSaves: Promise<IBackendUserWithSaves[]> = async () => {
const getUserSaves = async (setter: React.Dispatch<React.SetStateAction<IBackendUserWithSaves[]>>) => {
  const usersJson: backendUser[] = await fetchProtectedJson(`${host}/${usersMy}`); // /user/myCustomers
  const asyncRes: IBackendUserWithSaves[] = await Promise.all(usersJson.map(async (backendUser, index) => {
    const header = await fetchProtectedJson(`${host}/${save_header(backendUser?.email as string)}`);
    const backendUserWithSaves: IBackendUserWithSaves = { ...backendUser, saves: header }
    return backendUserWithSaves;
  }));

  console.log(asyncRes);
  setter(asyncRes)
}

enum GroupingTypeEnum {
  none,
  titles,
  customers
}

const ArticleSavedScroll = ({ headers, setSelectedRawSave, selectedProject, backendUser }: IProps) => {
  //   const arr = headers? [...headers] : [];
  //   arr.reverse();
  const { t, i18n } = useTranslation();
  const [groupingType, setGroupingType] = useState<GroupingTypeEnum | undefined>();
  const [selectedTitle, setSelectedTitle] = useState<string | undefined>();
  const [usersAndSaves, setUsersAndSaves] = useState<IBackendUserWithSaves[]>([]);
  const [selectedUserID, setSelectedUserID] = useState<string>();
  const [userHeaders, setUserHeaders] = useState<IContainersQueryShort[] | null>(headers || []);
  const [headersFiltered, setHeadersFiltered] = useState<IContainersQueryShort[] | null>(headers);

  const [open, setOpen] = useState(false);
  const titleGroupingModel = GroupingTypeEnum.titles;
  const customersGroupingModel = GroupingTypeEnum.customers;

  const titles =
    useMemo(() =>
      userHeaders?.map((elem) => elem.projectName).filter((item, pos, self) => self.indexOf(item) == pos).sort()
      , [userHeaders]);

  useEffect(() => {
    if (userRoles.seller <= (backendUser?.role as number)) {
      getUserSaves(setUsersAndSaves);
    }
  }, [])

  // useEffect(() => {

  // }, [savesHeaders]);

  useEffect(() => {
    setSelectedUserID(backendUser?.id);
    setSelectedTitle(undefined);
  }, [groupingType])

  useEffect(() => {
    const selectedSaves = usersAndSaves?.find(elem => elem.id === selectedUserID);
    if (selectedSaves) {
      setUserHeaders(selectedSaves.saves)
    }
    else {
      setUserHeaders(headers)
    }
  }, [selectedUserID, usersAndSaves, headers])

  useEffect(() => {
    if (headersFiltered && headersFiltered.length > 0) {
      selectProject(headersFiltered[0].docName);
    }
  }, [headersFiltered])
  useEffect(() => {
    const filetered = userHeaders?.filter((elem, index) => selectedTitle === undefined || elem.projectName === selectedTitle)
    setHeadersFiltered(filetered || []);
  }, [userHeaders, setHeadersFiltered, selectedTitle])
  const baseUserHeaders: IBackendUserWithSaves = useMemo(() => {
    return { ...backendUser as backendUser, saves: userHeaders as IContainersQueryShort[] }
  }, [backendUser, userHeaders])

  const selectProject = useCallback(
    async (id: string) => {
      if (!headersFiltered) {
        console.log('SHOUDL NOT HAPPEN');
        console.error('SHOUDL NOT HAPPEN');
        return;
      }
      const selectedHeader = headersFiltered.find(elem =>elem.docName === id);
      if (selectedHeader === undefined) {
        console.log('SHOUDL NOT HAPPEN');
        console.error('SHOUDL NOT HAPPEN');
        return;
      }
      const constainersQuery: ContainersQuery = await fetchProtectedJson(
        `${host}/${save_project(selectedHeader.docName, selectedHeader?.userAAD as string)}`,
      );
      if (!constainersQuery) {
        console.log('SHOUDL NOT HAPPEN');
        console.error('SHOUDL NOT HAPPEN');
        return;
      }
      constainersQuery.payload = JSON.parse((constainersQuery.payload as unknown) as string);
      const transformedQuery: ContainersQuery = DeserializeToObject(new ContainersQuery(), constainersQuery);
      setSelectedRawSave(transformedQuery);
    },
    [setSelectedRawSave, headersFiltered],
  );

  return (
    <div className={`${style.bottomCacher} bottomCacherX`}>
      <div className={style.labelsContainer}>
        <div className={`${style.label} ${!groupingType && style.highlighted}`} onClick={() => setGroupingType(undefined)}>
          <p>{t('saved')}</p>
          <p>{t('projects')}</p>
        </div>
        <div className={`${style.label} ${titleGroupingModel === groupingType && style.highlighted}`}
          onClick={() => { setGroupingType(titleGroupingModel); setOpen(true) }}>
          <p>{t('grouping by')}</p>
          <p>{t('project title')}</p>
          {/* <p>{t('projects')}</p> */}
        </div>
        {/* {userRoles.seller <= (backendUser?.role as number) && (
          <div className={`${style.label} ${customersGroupingModel === groupingType && style.highlighted}`}
            onClick={() => { setGroupingType(customersGroupingModel); setOpen(true) }}>
            <p>{t('projects')}</p>
            <p>{t('of customers')}</p>
          </div>
        )} */}
      </div>
      {groupingType && <ArticleSavedScrollSelection
        backendUser={backendUser}
        selectedUserID={selectedUserID}
        setSelectedUserID={setSelectedUserID}
        usersAndSaves={[baseUserHeaders, ...usersAndSaves]}
        titles={titles} selectedTitle={selectedTitle}
        setSelectedTitle={setSelectedTitle} />}
      {headersFiltered?.map((value, index) => {
        return (
          <ImageElement
            key={value.docName}
            value={value}
            index={index}
            selectProject={selectProject}
            uuid={headersFiltered[index].docName}
            selectedProject={selectedProject}
            totalNumber={headersFiltered.length}
          />
        );
      })}
      <Backdrop
        // sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={() => setOpen(false)}
        style={{ zIndex: 1000 }}
      >
        <Paper className={style.popupOverlay} onClick={(event) => { event.stopPropagation() }}>
          <Typography>{t('grouping by')}  {t('project title')}</Typography>
          {groupingType && <ArticleSavedScrollSelection
            backendUser={backendUser}
            selectedUserID={selectedUserID}
            setSelectedUserID={setSelectedUserID}
            usersAndSaves={[baseUserHeaders, ...usersAndSaves]}
            titles={titles} selectedTitle={selectedTitle}
            setSelectedTitle={setSelectedTitle} />}
        </Paper>

      </Backdrop>
    </div>
  );
};

export default ArticleSavedScroll;
