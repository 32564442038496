import React, { useCallback } from 'react';
// import logo from './logo.svg';
import './MainView.scss';
import SectionDouble from './../components/SectionDouble';
import { useHistory } from 'react-router-dom';
import useLogin from 'src/hooks/useLogin';
import ButtonTransparent from 'src/components/ButtonTransparent';
import RedirectController from 'src/components/RedirectController';
import { Trans, useTranslation } from 'react-i18next';
interface IProps {
  children?: React.ReactNode;
  forceLogin?: boolean | undefined;
  redirect?: boolean | undefined;
}

const NoPriviliges = (props: IProps) => {
  // const loginIndicator = useLogin();
  // console.log(loginIndicator);
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const desingClick = useCallback(() => {
    history.push('/main');
  }, [history]);
  return (
    <RedirectController {...props} >
      <div className="main__view__holder" style={{justifyContent: 'center'}}>
        {props.children}

        <ButtonTransparent
          onClick={desingClick}
          propStyle={{textTransform: "uppercase", padding: "10px", border: '3px solid white', alignSelf: "center" }}
        >
          <span>{t('no privileges - go back')}</span>
          {/* <h1>{t('login')}</h1> */}
        </ButtonTransparent>

      </div>
    </RedirectController>
  );
};

export default NoPriviliges;



