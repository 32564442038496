import React, { Children } from 'react';
// import logo from './logo.svg';
import './LoginView.scss';
import SectionDouble from './../components/SectionDouble';
import FirstArticleLogin from 'src/components/firstArticle/FirstArticleLogin';
import SecArticleLogin from 'src/components/secArticle/SecArticleLogin';
import { useHistory } from 'react-router-dom';
import { AzureAD, IAzureADFunctionProps, AuthenticationState } from 'react-aad-msal';
import { authProvider } from 'src/authProvider';

interface IProps {
  children?: React.ReactNode;
}

const LoginView = (props: IProps) => {
  let history = useHistory();
  return (
    <AzureAD provider={authProvider}>
      {(AzureADFunctionProps: IAzureADFunctionProps) => {
        const { login, logout, authenticationState, error, accountInfo } = AzureADFunctionProps;
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            history.push('/main');
          case AuthenticationState.Unauthenticated:
            return (
              <div className="login__view__holder">
                {props.children}
                <SectionDouble>
                  <>
                    <FirstArticleLogin />
                    <SecArticleLogin />
                  </>
                </SectionDouble>
              </div>
            );
          case AuthenticationState.InProgress:
            return null; // consider waiting screen?
        }
      }}
    </AzureAD>
  );
};

export default LoginView;
