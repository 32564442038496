export interface IConfigurationSchemaDTO {
  rows: number;
  columns: number;
  corridor: boolean;
  totalRow: number;
  totalColumns: number;
}

interface IConfigurationSchema extends IConfigurationSchemaDTO {
  //   rows: number;
  //   columns: number;
  //   corridor: boolean;
  title: string;
}

export class ConfigurationSchema implements IConfigurationSchema {
  rows!: number;
  columns!: number;
  corridor!: boolean;
  totalRow!: number;
  totalColumns!: number;
  corridorSetup: IConfigurationSchema;
  noCorridorSetup: IConfigurationSchema;
  title!: string;
  constructor() {
    this.corridorSetup = {
      rows: -1,
      columns: -1,
      corridor: true,
      totalRow: 1,
      totalColumns: 6,
      title: 'Schema with corridor',
    };
    this.noCorridorSetup = {
      rows: -1,
      columns: -1,
      corridor: false,
      totalRow: 3,
      totalColumns: 10,
      title: 'Schema without corridor',
    };
    Object.assign(this, this.noCorridorSetup);
    // this.rows = -1;
    // this.columns = -1;
    // this.corridor = false;
    // this.totalRow = 3;
    // this.totalColumns = 10;
  }
  getDtoValue = function (this: ConfigurationSchema): IConfigurationSchemaDTO {
    return {
      totalColumns:  this.totalColumns,
      totalRow: this.totalRow,
      columns: this.columns,
      corridor: this.corridor,
      rows: this.rows,
    };
  };
}

export const cal = 1;

export default ConfigurationSchema;
