import React, { useCallback } from 'react';
import './SecArticleBase.scss';
import style from './SecArticleLogin.module.scss';
import { ReactComponent as EmptyPersonSvg } from '../../assets/images/person_empty_icon.svg';
import { ReactComponent as FullPersonSvg } from '../../assets/images/person_full_icon.svg';
import ButtonTransparent from '../ButtonTransparent';
import { setArtificalServerResponse } from 'src/hooks/useLogin';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {}

const SecArticleLogin = (props: IProps) => {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const loginCallback = useCallback(() => {
    setArtificalServerResponse(true);
    history.push('/main');
  }, [history]);
  return (
    <article className={`SecArticleBase ${style.SecArticleLogin}`}>
      <div className={`${style.LoginHeader}`}>
        <EmptyPersonSvg color="white" />
        <FullPersonSvg color="white" />
        <EmptyPersonSvg color="white" />
      </div>
      {/* <LoginInput /> */}

      <ButtonTransparent propStyle={{ textTransform: 'uppercase'}} onClick={loginCallback}>
        <p className={style.MainButton}>{t('log in')}</p>
      </ButtonTransparent>
      <ButtonTransparent onClick={()=>history.push('/unlogged')}>
        <p className={style.SmallButton}>{t('Continue without login')}</p>
      </ButtonTransparent>
    </article>
  );
};

export default SecArticleLogin;
