import React, { useState, useCallback, useRef } from 'react';
import TableDisplay from './TableDisplay';
import logo from '../logo.svg';
import '../App.css';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, Redirect, useRouteMatch } from 'react-router-dom';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import ISessionParams from 'src/interfaces/ISessionParams';

interface IProps {
  displayErrorMessage: (message: string) => void;
  materialTableCallback: any;
  sessionParams?: ISessionParams;
}

//https://reacttraining.com/react-router/web/example/auth-workflow
function SettingView(props: any) {
  // const goToLink = useCallback(()=>{
  let { path, url } = useRouteMatch();
  const displayErrorMessage = useCallback((message: string) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  }, []);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('Start');
  const materialTableCallback: any = useRef(null);
  const admin: boolean = (props.backendUser?.role as number) >= 4000;
  console.log(props, admin);
  const api_regex = /^\/api\/.*/;
  const development: boolean = process.env.NODE_ENV === 'development';
  if (api_regex.test(window.location.pathname)) {
    return <div />;
  }
  const handleClick = (e: { preventDefault: () => void }) => {
    const stateValue = materialTableCallback.current?.state?.lastEditingRow;
    if (stateValue) {
      e.preventDefault();
    }
  };
  return (
    <>
      <Router>
        <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} autoHideDuration={3500}>
          <SnackbarContent
            style={{
              backgroundColor: red[900],
            }}
            message={snackbarMessage}
          />
        </Snackbar>
        <ul className="top-list">
          <li>
            <NavLink onClick={handleClick} activeClassName="selected" to={`${url}/kontenery`}>
              kontenery
            </NavLink>
          </li>
          <li>
            <NavLink onClick={handleClick} activeClassName="selected" to={`${url}/obrobki`}>
              obróbki
            </NavLink>
          </li>
          <li>
            <NavLink onClick={handleClick} activeClassName="selected" to={`${url}/ustawienia`}>
              ustawienia
            </NavLink>
          </li>
          {admin &&
            (<li>
              <NavLink onClick={handleClick} activeClassName="selected" to={`${url}/firmy`}>
                firmy
              </NavLink>
            </li>)
          }
        </ul>
        <Switch>
          <Route path={`${path}/:id`}>
            <TableDisplay
              sessionParams={props}
              displayErrorMessage={displayErrorMessage}
              materialTableCallback={materialTableCallback}
            />
          </Route>
          <Redirect from={`${path}/`} to={`${path}/kontenery`} />
        </Switch>
      </Router>
    </>
  );
}

export default SettingView;
