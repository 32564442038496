import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Unity, { UnityContent } from 'react-unity-webgl';
import style from './UnityProject.module.scss';
import {
  setCurrentSettings,
  resetCurrentSettings,
  containsMainSettings,
  setMainSettings,
  obtainCurrentSettings,
} from 'src/utilities/obtainCurrentSettings';
import { Button } from '@material-ui/core';
import { ReactComponent as TurnOff } from 'src/assets/images/turn_off.svg';
import UserAppType from 'src/enums/appType';
import { useHistory, useLocation } from 'react-router-dom';

import { ProjectStartingConfiguration } from 'src/models/SetupViewModel';
import { useTranslation } from 'react-i18next';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface Vector2 {
  x: number;
  y: number;
}
const calculateSize = (): Vector2 => {
  // const startWidth = window.innerHeight;
  // const startWidth = window.innerHeight;
  let baseHeight = window.innerHeight * 0.7;
  let baseWidth = window.innerWidth * 0.7;

  // clamp proportions
  if (baseHeight * 1.8 < baseWidth) {
    baseWidth = baseHeight * 1.8;
  }
  return { x: baseWidth, y: baseHeight };
};
//  temp2.unityInstance.SetFullscreen(0);

const Progress = ({ progress }: { progress: number }) => {
  if (progress > 0.95) {
    return null;
  }
  return (
    <div className={style.progress_holder}>
      <div className={style.progress_fullfill} style={{ width: `${progress * 100}%` }} />
    </div>
  );
};

const ChangeUnityHtmlElementSize = (node: any, width: number, height: number) => {
  const nNode: HTMLElement = node;
  // nNode.style.width = `${width}px`;
  // nNode.style.height = `${height}px`;
  node.setAttribute('height', height);
  node.setAttribute('width', width);
  setTimeout(() => {
    if (document.fullscreenElement) {
      // nNode.style.width = `${width}px`;
      // nNode.style.height = `${height}px`;
      node.setAttribute('height', height);
      node.setAttribute('width', width);
    }
  }, 1000);
};

interface IProps {
  children?: React.ReactNode;
  appType: UserAppType;
}

const UnityProject = (props: IProps) => {
  let query = useQuery();
  const unityContent = useMemo(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlAdress = query.has('altUnity')? 'alt/Release_2021_7_10a.json' : 'unity/2024_06_17.json';
    return new UnityContent(urlAdress, 'unity/UnityLoader.js?v=40', { adjustOnWindowResize: true })
  }, []);
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const inputEl = useRef(null);
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    if (unityContent) {
      unityContent.on('progress', (progression: number) => {
        setProgress(progression);
      });
    }
  }, [unityContent]);
  useEffect(() => {
    document.addEventListener('fullscreenchange', (event) => {
      if (navigator.userAgent.includes('Firefox')) {
        const unityContentX = unityContent as any;
        if (document.fullscreenElement) {
          ChangeUnityHtmlElementSize(
            unityContentX.unityComponent.htmlElement.children[0],
            window.screen.width,
            window.screen.height,
          );
        } else {
          ChangeUnityHtmlElementSize(
            unityContentX.unityComponent.htmlElement.children[0],
            unityContentX.unityComponent.htmlElement.offsetWidth,
            unityContentX.unityComponent.htmlElement.offsetHeight,
          );
        }
      }
    });
    // exit if empty! SettingsArgs
    if (props.appType === UserAppType.bezlogowania) {
      const artStart = new ProjectStartingConfiguration();
      artStart.ProjectTitle.stringInput = 'bez logowania';
      setMainSettings(artStart.getDto());
    }
    if (!containsMainSettings() && !query.has('adress')) {
      history.push('/setup');
    }
    setCurrentSettings({
      frontendVersion: 'dev',
      appType: query.has('6x3') ? UserAppType.wymiar6x3 : props.appType,
      language: i18n.language,
    });
    return () => {
      resetCurrentSettings();
    };
  }, []);

  const onFullScreen = useCallback(() => {
    unityContent.setFullscreen(true);

  }, [unityContent]);
  return (
    <div className={style.main__view__holder}>
      {props.children}
      {/* <div style={{ width: `${sizes.x}px`, height: `${sizes.y}px`, margin: 'auto' }}> */}
      <div className={style.container}>
        {/* <Unity ref={inputEl} width={`${sizes.x}px`} height={`${sizes.y}px`} unityContent={unityContent} /> */}
        <Unity ref={inputEl} height="100%" width="100%" unityContent={unityContent} />
        <Button className={style.button} onClick={onFullScreen}>
          <TurnOff color="white" />
        </Button>
        <Progress progress={progress} />
      </div>
    </div>
  );
};

UnityProject.defaultProps = {
  appType: UserAppType.standard,
};

export default UnityProject;
