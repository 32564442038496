import React, { useState, useCallback, useEffect, useRef, Dispatch, SetStateAction, useImperativeHandle } from 'react';
import MaterialTable, { MaterialTableProps, Column, Options, MTableCell } from 'material-table';
import CellController from 'src/controllers/CellControlle';
import MaterialIcons from 'src/assets/MaterialIcons';
import EditFieldController from 'src/controllers/EditFieldController';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import { host, setupDatasAll } from 'src/models/urlEndpoints';

import { red } from '@material-ui/core/colors';
import AlertDialog from 'src/utilities/EncodeCsv';
import fetchProtectedJson from 'src/utilities/fetchProtected';
import ISessionParams from 'src/interfaces/ISessionParams';

const firstColumnModels = (sudo: boolean, id: string): Column<object>[] => [
  {
    title: 'Id',
    field: 'id',
    type: 'numeric',
    editable: sudo ? undefined : 'never',
    cellStyle: {
      minWidth: 5,
      width: 10,
      maxWidth: 20,
      // display: 'block',
    },
    headerStyle: {
      minWidth: 5,
      width: 10,
      maxWidth: 20,
      // display: 'block',
    },
  },
  {
    title: id === 'firmy'? 'Nazwa firmy' : 'Nazwa',
    field: 'nazwa',
    editable: sudo ? undefined : 'never',
  },
  { title: id === 'firmy'? 'ID Rekord' : 'Kod', field: 'kod' },
  // { title: 'co', field: 'co', type: 'numeric', lookup:{ 34: 'İstanbul', 63: 'Şanlıurfa' } },
];

class priceCollumn {
  title: string;
  field: string;
  constructor(collumnName: string, cena?: string | undefined) {
    this.title = cena || 'Cena';
    this.field = collumnName;
  }
}

interface IStateObject {
  columns: Column<object>[];
  data: object[];
  options?: Options;
}

// https://github.com/mbrn/material-table/blob/master/demo/demo.js
interface IProps {
  displayErrorMessage: (message: string) => void;
  materialTableCallback: any;
  sessionParams?: ISessionParams;
}
interface ParamTypes {
  id: string;
}
interface settingsResponse {
  dateAdded: string;
  userAddedId: string;
  dateModified: string;
  userModifiedId: string;
  version: number;
  id: string;
  payload: string;
}

const TableDisplay = ({ displayErrorMessage, materialTableCallback, sessionParams }: IProps) => {
  // console.log(sessionParams);
  let { id } = useParams<ParamTypes>();
  const downloadPath: string | undefined =
    id === 'kontenery' ? 'kontenery' : id === 'obrobki' ? 'obrobki' : id === 'ustawienia' ? 'ustawienia' : id === 'firmy' ? 'firmy' : 'other';
  console.log(sessionParams);
  const sudo: boolean = (sessionParams?.backendUser?.role as number) >= 5000 || id === 'firmy' && (sessionParams?.backendUser?.role as number) >= 4000;
  const basicPriceId = 3;
  const downloadCsv = useCallback(async () => {
    const resp = (await fetchProtectedJson(`${host}/${setupDatasAll(downloadPath)}`)) as settingsResponse;
    try {
      const value = resp.payload;
      // const reader = resp.body?.getReader();
      // const decoder = new TextDecoder('utf-8');
      // const result = await reader?.read();
      // const value = decoder.decode(result?.value);
      // const value = await resp.text();
      const columnsArray = value.split('\n');

      const firstRowLength: number = columnsArray[0].split(';').length;

      const objectModel = columnsArray.map((row, index) => {
        const elem = row.split(';').slice(0, firstRowLength);
        const filteredElements: any = elem.flatMap((elemVal, elemID): object => {
          if (elemID === 0) {
            return [['id', index]];
          } else if (elemID === 1) {
            return [['nazwa', elemVal]];
          } else if (elemID === 2) {
            return [['kod', elemVal]];
          } else if (elemID >= basicPriceId) {
            return [
              [
                id === 'ustawienia' || id === 'firmy' ? `parametr_${elemID - basicPriceId + 1}` : `Cena_${elemID - basicPriceId + 1}`,
                elemVal === 'false' ? false : elemVal === 'true' ? true : elemVal,
              ],
            ];
          }
          return [];
        });
        return Object.fromEntries(filteredElements);
      });
      // console.log(objectModel, id);
      const columnsModel = [...firstColumnModels(sudo, id)];
      const firstRow = Object.values(objectModel)[0];

      const columnsFromCsv = Object.values(firstRow).flatMap((colVal: string, colID: number): object => {
        if (colID === basicPriceId) {
          return [
            new priceCollumn(
              id === 'ustawienia' ? `parametr_${colID - basicPriceId + 1}` : id === 'firmy' ? `parametr_${colID - basicPriceId + 1}` : `Cena_${colID - basicPriceId + 1}`,
              id === 'ustawienia' ? 'parametr' : id === 'firmy' ? colVal : 'Cena Podstawowa',
            ),
          ];
        }
        if (colID > basicPriceId ) {
          return [
            new priceCollumn(
              id === 'ustawienia' ? `parametr_${colID - basicPriceId + 1}` : id === 'firmy' ? `parametr_${colID - basicPriceId + 1}` : `Cena_${colID - basicPriceId + 1}`,
              id === 'ustawienia' ? colVal : colVal,
            ),
          ];
        }
        return [];
      });

      setState({
        ...state,
        columns: [...columnsModel, ...columnsFromCsv],
        data: objectModel,
      });
    } catch (e) {
      console.log(e);
      console.error(e);
    }
  }, [downloadPath, id]);

  useEffect(() => {
    downloadCsv();
  }, [downloadCsv]);
  const [state, setState] = useState<IStateObject>({
    columns: [...firstColumnModels(sudo, id)],
    data: [],
  });

  // callbacks
  const removeRow = useCallback(
    (event, rowData) => {
      const rowName: string | undefined = state.columns[state.columns.length - 1].field;
      const castName = rowName || '';
      setState({
        ...state,
        columns: [...state.columns.splice(0, Math.max(state.columns.length - 1, 3))],
        data: state.data.map((s: any) => {
          delete s[castName];
          return s;
        }),
      });
    },
    [state],
  );
  const addRow = useCallback(
    (event, rowData) => {
      const castName =
        id === 'ustawienia' ? `parametr_${state.columns.length - 2}` : `Cena_${state.columns.length - 2}`;
      setState({
        ...state,
        columns: [...state.columns, new priceCollumn(castName, id === 'ustawienia' ? 'parametr' : undefined)],
        data: state.data.map((s: any) => {
          return { ...s, [castName]: -2 };
        }),
      });
    },
    [state],
  );

  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <>
      <MaterialTable
        tableRef={materialTableCallback}
        icons={MaterialIcons}
        title={id}
        columns={state.columns}
        data={state.data}
        components={{
          Cell: (props) => <CellController {...props} />,
          EditField: (props) => <EditFieldController {...props} />,
        }}
        actions={[
          {
            icon: MaterialIcons.IndeterminateCheckBox as any,
            tooltip: 'Substract Column',
            isFreeAction: true,
            onClick: removeRow,
            hidden: id === 'firmy'
          },
          {
            icon: MaterialIcons.Add as any,
            tooltip: 'Add Column',
            isFreeAction: true,
            onClick: addRow,
            hidden: id === 'firmy'
          },
          {
            icon: () => (
              <Button variant="contained" color="primary" style={{ backgroundColor: '#bb2727' }}>
                Zapisz
              </Button>
            ),
            tooltip: 'Save',
            isFreeAction: true,
            onClick: () => {
              setModalOpen(true);
            },
          },
        ]}
        localization={{
          header: {
            actions: 'Edycja',
          },
        }}
        options={{
          headerStyle: {
            backgroundColor: '#bb2727',
            color: '#FFF',
          },
          rowStyle: {
            backgroundColor: '#EEE',
          },
          columnsButton: true,
          exportCsv: (x, y) => {},
          draggable: sudo ? true : false,
          pageSize: 20,
          pageSizeOptions: [20, 40, 80],
        }}
        editable={{
          // isEditable: (rowData: any) => rowData.id > 2,// || development,
          // isDeletable: (rowData: any) => rowData.is > 2,// || development,
          onRowUpdate: (newData, oldData: any) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                let wrong: string | boolean = false;
                if (oldData) {
                  setState((prevState) => {
                    console.log(newData, state.columns);
                    const newObj = Object.fromEntries(
                      Object.entries(newData).map(([k, v]: [string, any]) => {
                        if (
                          (k.includes('Cena') && oldData.id > 0 && isNaN(v)) || // TO CHECK
                          // k === 'Cena Podstawowa' ||
                          v === ''
                        ) {
                          wrong = v;
                          return [k, oldData[k]];
                        }
                        return [k, v];
                      }),
                    );

                    const data = [...prevState.data];
                    data[data.indexOf(oldData)] = newObj;
                    return { ...prevState, data };
                  });
                }
                wrong && displayErrorMessage(`Niewłaściwy parametr ${wrong}`);
                resolve(null);
              }, 10);
            }),
          onRowAdd: !sudo
            ? undefined
            : (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      const data = state.data;
                      data.push(newData);
                      setState((prevState) => {
                        return { ...prevState, data };
                      });
                    }
                    resolve(null);
                  }, 10);
                }),
          onRowDelete: !sudo
            ? undefined
            : (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    {
                      const data = state.data;
                      const index = data.indexOf(oldData);
                      data.splice(index, 1);
                      setState((prevState) => {
                        return { ...prevState, data };
                      });
                    }
                    resolve(null);
                  }, 10);
                }),
        }}
      />
      <AlertDialog
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        data={state.data}
        downloadPath={downloadPath}
        displayErrorMessage={displayErrorMessage}
      />
    </>
  );
};

export default TableDisplay;
