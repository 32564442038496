import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them)
export const languages = ['en', 'pl'];
const resources = {
  en: {
    translation: {
      administrator: 'administrator',
      'chosen parameters': 'Chosen parameters',
      'columns number': 'columns number',
      company: 'Company',
      configurator: 'configurator',
      'containers number': 'containers number',
      'Continue without login': 'Continue without login',
      'Currency': 'Currency',
      custommer: 'custommer',
      'date send': 'Date send',
      discount: 'Discount',
      'Drainage':'drainage', 
      'first name': 'Name',
      'floor number': 'floor number',
      'grouping by':'grouping by',
      guest: 'guest',
      'in the name of client': 'in the name of client', 
      'individual project': 'Individual project',
      inquiries: 'Inquiries',
      'insert selected containers':'Insert selected containers',
      'insert title':'INSERT TITLE',
      'last name': 'Last name',
      load: "load",
      'log out': 'log out',
      'log in': 'log in',
      login: 'login',
      'main page': 'main page',
      'make project': 'design',
      'ManyFloors': 'many floors',
      'ModuleHeight': 'module height',
      'ModuleLength': 'module length',
      'ModuleWidth': 'module width',
      'netto price': 'netto price',
      'new': 'New',
      'NIE': 'no',
      no: 'No',
      'no privileges - go back': 'no privileges - go back',
      'no title': 'no title',
      none: 'brak',
      'of containers': 'of containers',
      'of customers': 'of customers',
      'perimeter gutter': 'perimeter gutter',
      price: 'Price',
      PriceGroup: 'Price group',
      'privacy policy': 'PRIVICY POLICY',
      'project code': 'Project code',
      'project name': 'Project name',
      'project title': 'project title',
      project: 'project',
      projects: 'projects',
      removed: 'removed',
      role: 'Role',
      'row number': 'row number',
      saved: 'saved',
      'see all inquires': 'See all inquires',
      seller: 'seller',
      'Schema with corridor': 'Schema with corridor',
      'Schema without corridor': 'Schema without corridor',
      'start empty': 'Start empty project',
      sudo: 'developer',
      'TAK': 'YES',
      'through walls': 'through walls',
      'total number': 'total number',
      'trade supervisor': 'Trade supervisor',
      users: 'Users',
      ustawienia: 'settings',
      'with configurator': 'design of configurator',
    },
  },
  pl: {
    translation: {
      administrator: 'administrator',
      'back': 'powrót',
      'chosen parameters': 'Wybór parametrów',
      'columns number': 'liczba kolumn',
      company: 'Firma',
      configurator: 'konfigurator',
      'Continue without login': 'Projektuj bez logowania',
      'containers number': 'liczba kontenerów',
      'Currency': 'Waluta',
      custommer: 'klient',
      'date send': 'Data przesłania',
      discount: 'Rabat',
      'Drainage':'odwodnienie', 
      'first name': 'Imie',
      'floor number': 'liczba kondygnacji',
      'grouping by':'grupy po',
      guest: 'gość',
      'in the name of client': 'w imieniu klienta', 
      'individual project': 'Projekt własny',
      inquiries: 'Zapytania',
      'insert selected containers':'Wstaw zaznaczone kontenery',
      'insert title':'WPISZ TYTUŁ',
      'last name': 'Nazwisko',
      load: "wczytaj",
      'log out': 'wyloguj',
      'log in': 'zaloguj',
      login: 'logowanie',
      'main page': 'strona główna',
      'make project': 'projektuj',
      'ManyFloors': 'wiele kondygnacji',
      'ModuleHeight': 'wysokość modułu',
      'ModuleLength': 'długość modułu',
      'ModuleWidth': 'szerokość modułu',
      'netto price': 'wartość netto',
      'new': 'Nowy',
      'NIE': 'nie',
      no: 'Nr',
      'no privileges - go back': 'brak uprawnień - powrót',
      'no title': 'bez nazwy',
      none: 'none',
      'of containers': 'kontenerów',
      'of customers':'klientów',
      'perimeter gutter': 'rynna obwiedniowa',
      price: 'Cena',
      PriceGroup: 'Grupa cenowa',
      'privacy policy': 'POLITYKA PRYWATNOŚCI',
      'project code': 'Kod projektu',
      'project name': 'Nazwa projektu',
      'project title': 'nazwie',
      project: 'projekt',
      projects: 'projekty',
      removed: 'usunięty',
      role: 'Rola',
      'row number': 'liczba wierszy',
      saved: 'zapisane',
      'see all inquires': 'Zobacz wszystkie zapytania',
      seller: 'sprzedawca',
      'Schema with corridor': 'Układ z korytarzem',
      'Schema without corridor': 'Układ bez korytarza',
      'start empty': 'Rozpocznij bez wstawiania',
      sudo: 'programista',
      'TAK': 'tak',
      'through walls': 'przez ściany',
      'total number': 'łączna liczba',
      'trade supervisor': 'Opiekun handlowy',
      users: 'Użytkownicy',
      ustawienia: 'ustawienia',
      'with configurator': 'konfiguratora',
    },
  },
};

i18n
.use(LanguageDetector)
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
