import {
  ProjectStartingConfiguration,
  ModuleWidthType,
  ModuleHeightType,
  DrainageType,
  ManyFloorsType,
  SelectableParameterArrayString,
  SelectableParameterBool,
  SelectableParameterEnum,
} from './../models/SetupViewModel';

const testFrame = (mergedState: ProjectStartingConfiguration, isMobilbox: boolean, isModular: boolean): ProjectStartingConfiguration => {
  // Width test
  if ((mergedState.ModuleWidth.getDtoValue.call(mergedState.ModuleWidth) as ModuleWidthType) === '2990 mm') {
    return {
      ...mergedState,
      ModuleLength: new SelectableParameterArrayString(['6058 mm']),
      ModuleHeight: new SelectableParameterArrayString(['2800 mm']),
      ManyFloors: new SelectableParameterBool(['TAK']),
      Drainage: new SelectableParameterArrayString(['perimeter gutter']),
      Frame: new SelectableParameterEnum('F3x6'),
    };
  }
  // const wideEnabled = !isMobilbox;
  let artState: ProjectStartingConfiguration = new ProjectStartingConfiguration();
  artState.copyValues.call(artState, mergedState);
  // if (!wideEnabled) {
  //   artState.ModuleWidth = new SelectableParameterArrayString(['2438 mm'])
  // }

  if (!isModular) {
    artState.Currency = new SelectableParameterArrayString([])
  }

  const Height2800Test =
    (artState.ModuleHeight.getDtoValue.call(artState.ModuleHeight) as ModuleHeightType) === '2800 mm';

  const ManyFlorsTest = artState.ManyFloors.getDtoValue.call(artState.ManyFloors);

  const DrainTest =
    (artState.Drainage.getDtoValue.call(artState.Drainage) as DrainageType) === 'perimeter gutter';
  console.log(isMobilbox);

  if (Height2800Test) {
    if (ManyFlorsTest) {
      if (DrainTest || isMobilbox) {
        // artState = { ...artState, Frame: new SelectableParameterEnum('MB') };
        if (isMobilbox) {
          return {
            ...artState,
            // Frame: new SelectableParameterEnum('MB'),
            Drainage: new SelectableParameterArrayString(['perimeter gutter']),
            ManyFloors: new SelectableParameterBool(['TAK']),
          };
        }
        else {
          return {
            ...artState,
            Frame: new SelectableParameterEnum('MB'),
          };
        }
      }
      else {
        return { ...artState, Frame: new SelectableParameterEnum('SR') };
      }
    } else {
      return {
        ...artState,
        Drainage: new SelectableParameterArrayString(['through walls']),
        Frame: new SelectableParameterEnum('ECO'),
      };
    }
  } else {
    if (ManyFlorsTest) {
      return {
        ...artState,
        Drainage: new SelectableParameterArrayString(['through walls']),
        Frame: new SelectableParameterEnum('ECO_S355'),
      };
    } else {
      return {
        ...artState,
        Drainage: new SelectableParameterArrayString(['through walls']),
        Frame: new SelectableParameterEnum('ECO'),
      };
    }
  }
};

export default testFrame;