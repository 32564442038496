import React, { useState, useCallback, useEffect, useMemo } from 'react';
import MaterialTable, { MaterialTableProps, Column, Options, MTableCell } from 'material-table';
import UserCellController from 'src/controllers/UserCellController';
import MaterialIcons from 'src/assets/MaterialIcons';
import UserEditFieldController from 'src/controllers/UserEditFieldController';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import { host, usersAll, inquiryHeader, userUuId } from 'src/models/urlEndpoints';

import AlertDialog from 'src/utilities/EncodeCsv';
import fetchProtectedJson from 'src/utilities/fetchProtected';
import BackendUserModel from 'src/models/BackendUserModel';
import { KeyboardArrowDown } from '@material-ui/icons';
import backendUser, { backendUserDTO } from 'src/interfaces/backendUser';
// import userRoles from 'src/enums/userRoles';
import userRoles from 'src/enums/userRoles';
import { useTranslation } from 'react-i18next';
import currencyType from 'src/enums/currencyType';

interface IStateObject {
  columns: Column<object>[];
  data: backendUserDTO[];
  options?: Options;
}

// https://github.com/mbrn/material-table/blob/master/demo/demo.js
interface IProps {
  isAdmin: boolean;
  displayErrorMessage: (message: string) => void;
  materialTableCallback: any;
  usersQuery: () => Promise<backendUser[]>;
  firstColumnModels: Column<object>[];
  filtering?: boolean;
}
interface ParamTypes {
  id: string;
}

function array_move(arr: Array<any>, old_index: number, new_index: number) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

// returns [2, 1, 3]
console.log(array_move([1, 2, 3], 0, 1));

const UserTable = ({ isAdmin, displayErrorMessage, materialTableCallback, usersQuery, firstColumnModels, filtering = false }: IProps) => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(Math.random());
  const downloadJson = useCallback(async () => {
    try {
      const usersJson = await usersQuery();

      if (!Array.isArray(usersJson)) {
        throw 'wrong data';
      }
      let sellerNr = 0;
      let sellerCounter = 0;

      usersJson.forEach((elem, index) => {
        elem.tempIndex = index * 1000;
      });
      usersJson.forEach((elem, index) => {
        if (elem.tradeSupervisor) {
          elem.tempIndex = usersJson.find(e => e.idAAD === elem.tradeSupervisor?.idAAD)?.tempIndex as number + elem.tempIndex / 1000;
        }
      });

      const obtainedData: backendUserDTO[] = usersJson
        .sort((prev, next) => prev.tempIndex - next.tempIndex)
        .map((value, index) => {
          if (value.tradeSupervisor) {
            sellerCounter++;
          } else {
            sellerNr++;
            sellerCounter = 0;
          }
          let displeyNumber = sellerNr + sellerCounter / (sellerCounter.toString().length * 10);

          return {
            ...value,
            id: displeyNumber,
            tradeSupervisor: value.tradeSupervisor?.email ?? null,
          };
        });
      // usersJson.fil
      const companyRow = firstColumnModels.find(elem =>elem.field === "company");
      const companies = Object.fromEntries( obtainedData.filter((elem: backendUserDTO, index, self) => self.indexOf(elem)).map(elem => [elem.company, elem.company || "brak"]));
      console.log(companies);
      if (companyRow){
        companyRow.lookup = companies;
      }
      setState({
        ...state,
        columns: [...firstColumnModels],
        data: obtainedData,
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    downloadJson();
  }, [downloadJson, refresh]);
  const [state, setState] = useState<IStateObject>({
    columns: [...firstColumnModels],
    data: [],
  });

  const memoizedSellers = useMemo(() => {
    return state.data.filter((elem: backendUserDTO) => parseInt(elem.role as string) >= userRoles.seller);
  }, [state]);

  return (
    <>
      <MaterialTable
        tableRef={materialTableCallback}
        icons={MaterialIcons}
        title={t('users')}
        columns={state.columns}
        data={state.data}
        components={{
          Cell: (props) => <UserCellController {...props} />,
          EditField: (props) => <UserEditFieldController {...props} memoizedSellers={memoizedSellers} />,
        }}
        actions={
          [
            // {
            //   icon: () => (
            //     <Button variant="contained" color="primary" style={{ backgroundColor: '#bb2727' }}>
            //       Zapisz
            //     </Button>
            //   ),
            //   tooltip: 'Add Column',
            //   isFreeAction: true,
            //   onClick: () => {
            //     setModalOpen(true);
            //   },
            // },
          ]
        }
        localization={{
          header: {
            actions: 'Edycja',
          },
        }}
        options={{
          headerStyle: {
            backgroundColor: '#bb2727',
            color: '#FFF',
          },
          rowStyle: {
            backgroundColor: '#EEE',
          },
          columnsButton: true,
          exportCsv: (x, y) => { },
          draggable: false,
          pageSize: 5,
          pageSizeOptions: [10, 20, 40],
          emptyRowsWhenPaging: false,
          filtering: filtering,
        }}
        style={{width: '100vw'}}
        editable={{
          // isEditable: (rowData: any) => rowData.id > 2,// || development,
          // isDeletable: (rowData: any) => rowData.is > 2,// || development,

          onRowUpdate: !isAdmin
            ? undefined
            : (newData, oldData: any) =>
              new Promise((resolve, reject) => {
                console.log(newData);
                const selectedUser = newData as backendUserDTO;
                const nBody: backendUserDTO = {};
                const parsedToInt = parseFloat((selectedUser.role as unknown) as string);
                if (selectedUser.role !== oldData.role && !isNaN(parsedToInt)) {
                  nBody.role = parsedToInt;
                }

                if (selectedUser.currencyUsed !== oldData.currencyUsed ) {
                  nBody.currencyUsed = selectedUser.currencyUsed ==  1000?1000:0;
                }
                console.log(selectedUser.currencyUsed);

                if (selectedUser.tradeSupervisor !== oldData.tradeSupervisor) {
                  const seller = memoizedSellers.find((elem) => selectedUser.tradeSupervisor === elem.email);
                  if (seller) {
                    nBody.tradeSupervisor = seller?.idAAD;
                  } else {
                    nBody.tradeSupervisor = '00000000-0000-0000-0000-000000000000';
                  }
                }
                if (
                  selectedUser.discount &&
                  selectedUser.discount >= 0 &&
                  selectedUser.discount <= 60 &&
                  selectedUser.discount !== oldData.discount
                ) {
                  nBody.discount = selectedUser.discount;
                }
                if (
                  selectedUser.companyGroup !== oldData.companyGroup
                ) {
                  if (selectedUser.companyGroup)
                  {
                    nBody.companyGroup = selectedUser.companyGroup;
                  }
                  else
                  {
                    nBody.companyGroup = "-1";
                  }
                }
                if (Object.keys(nBody).length === 0) {
                  displayErrorMessage('Dane są takie same lub niepoprawne');
                  reject(null);
                  return;
                }

                fetchProtectedJson(
                  `${host}/${userUuId(selectedUser.idAAD as string)}${nBody.tradeSupervisor === '00000000-0000-0000-0000-000000000000'
                    ? '/' + '00000000-0000-0000-0000-000000000000'
                    : ''
                  }`,
                  {
                    method: 'PATCH',
                    body: JSON.stringify(nBody),
                  },
                )
                  .then((resp) => {
                    if (resp) {
                      setRefresh(Math.random());
                      displayErrorMessage(`Dane zapisane!`);
                      resolve(null);
                      return;
                    } else {
                      displayErrorMessage('Nie udało się zapisać danych');
                      reject(null);
                      return;
                    }
                  })
                  .catch((e) => {
                    displayErrorMessage('Nie udało się zapisać danych');
                    reject(null);
                    return;
                  });
                // }, 10);
              }),
        }}
      />
      {/* <AlertDialog
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        data={state.data}
        downloadPath={downloadPath}
        displayErrorMessage={displayErrorMessage}
      /> */}
    </>
  );
};

export default UserTable;
