const DeserializeToObject = (classObject: any, serializedObject: object) => {
  Object.assign(classObject, serializedObject);
  if (classObject.hasOwnProperty('serializable')) {
    Object.entries(classObject.serializable).forEach(([key, value]) => {

      if (Array.isArray(value)) {
        const nClass = value as any;
        // console.log(new nClass[0]());
        classObject[key] = classObject[key].map((val: object) =>
          DeserializeToObject(new nClass[0](), val),
        );
      } else {
        const nClass = value as any;
        // console.log(new nClass());
        classObject[key] = DeserializeToObject(new nClass(), classObject[key]);
      }
    });
  }
  return classObject;
};

export default DeserializeToObject;
