import React from 'react';
// import logo from './logo.svg';
import './MainView.scss';
import SectionDouble from './../components/SectionDouble';
import FirstArticleMain from 'src/components/firstArticle/FirstArticleMain';
import SecArticleMain from 'src/components/secArticle/SecArticleMain';
import useLogin from 'src/hooks/useLogin';

import RedirectController from 'src/components/RedirectController';
interface IProps {
  children?: React.ReactNode;
  forceLogin?: boolean | undefined;
  redirect?: boolean | undefined;
}

const MainView = (props: IProps) => {
  // const loginIndicator = useLogin();
  // console.log(loginIndicator);
  return (
    <RedirectController {...props} >
      <div className="main__view__holder">
        {props.children}
        <SectionDouble>
          <>
            <FirstArticleMain />
            <SecArticleMain />
          </>
        </SectionDouble>
      </div>
    </RedirectController>
  );
};

export default MainView;



