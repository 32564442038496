class dictionaryElement {
  longText: string;
  shortText: string;
  constructor(shortText: string, longText: string) {
    this.shortText = shortText;
    this.longText = longText;
  }
}

export const switchDescription = new dictionaryElement('sw', 'z włącznikiem ');
export const socketDescription = new dictionaryElement('so', 'z gniazdkiem ');

export const windowDescription = new dictionaryElement('w', 'Panel z oknem standard ');
export const doorDescription = new dictionaryElement('d', 'Panel z drzwiami standard ');

export const windowServeDescription = new dictionaryElement('ws', 'Panel z oknem podawczym ');
export const emptyOpeningDescription = new dictionaryElement('eo', 'Panel z otworem pustym ');
export const windowPanoramaDescription = new dictionaryElement('wp', 'Panel z oknem panorama ');
export const doorGlassDescription = new dictionaryElement('dg', 'Panel drzwiami szklanymi ');
export const windowSanitDescription = new dictionaryElement('ws', 'Panel z oknem sanitarnym ');
export const doorDoubleDescription = new dictionaryElement('dd', 'Panel z drzwiami podwójnymi ');



export const normalDescription = new dictionaryElement(
  'n',
  'Panel standardowy ',
);
