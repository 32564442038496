import React, { useCallback } from 'react';
import style from './IntegerSlider.module.scss';
// import logo from 'src/assets/images/modular-logo-white.png';

interface IProps {
  displayText: string;
  min: number;
  max: number;
  value: number;
  setValue?: (val: number) => void;
}

const IntegerSlider = (props: IProps) => {
  const changeValue = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (!props.setValue) {
        return;
      }
      props.setValue(+event.currentTarget.value);
    },
    [props],
  );
  return (
    <div className={style.IntegerSlider}>
      <div>
        <div className={style.redBox} />
        <span>{props.displayText}</span>
      </div>
      <input
        className={style.slider}
        contentEditable={true}
        type="range"
        min={props.min}
        max={props.max}
        value={props.value}
        onChange={changeValue}
        disabled={!props.setValue}
      />
      <p className={style.floatingNumber}>
        <span
          style={{
            left: `${
              ((props.value - props.min) * 100) / Math.max((props.max - props.min), 1)
            }%`,
          }}
          className={style.floatingNumber}
        >
          {props.value}
        </span>
      </p>
    </div>
  );
};

export default IntegerSlider;
