import React from 'react';
import 'src/assets/fonts/_fontImporter.scss';
import 'src/styles/_reset-src.scss';
import 'src/styles/_tableParser.scss';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link, NavLink, Redirect } from 'react-router-dom';
import MainStaticContainer from 'src/components/MainStaticContainer';
import NoPriviliges from 'src/views/NoPriviliges';
import LoginView from 'src/views/LoginView';
import MainView from 'src/views/MainView';
import ProjectSaveView from 'src/views/ProjectSaveView';
import StartingSchema from 'src/views/StartingSchema';
import HeaderContainer from './components/HeaderContainer';
import FooterBasic from 'src/components/FooterBasic';
import ConfiguratorHeader from 'src/components/ConfiguratorHeader';
import LanguageController from 'src/components/LanguageController';
import LogOutButton from 'src/components/LogOutButton';
import { fetchUserData } from 'src/utilities/obtainUserData';
import { obtainCurrentSettings } from 'src/utilities/obtainCurrentSettings';
import obtainToken from 'src/utilities/obtainToken';
import { UserSession } from './utilities/obtainUserData';
import NavigationHeader from 'src/components/NavigationHeader';
import UnityProject from 'src/views/UnityProject';
import Inquiry from 'src/views/Inquiry';
import ContainersConfiguration from './views/ContainersConfiguration';
import SettingView from './views/SettingView';
import UserTableController from 'src/controllers/UserTableController';
import RedirectController from 'src/components/RedirectController';
import userRoles from 'src/enums/userRoles';
import InquiriesTableController from './controllers/InquiriesTableController';
import UserAppType from 'src/enums/appType';
import SetupView from 'src/views/SetupView';

function reportWindowSize() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}
reportWindowSize();
window.addEventListener('resize', reportWindowSize);
// if (process.env.NODE_ENV === 'development') {
(global as any).fetchUserData = fetchUserData;
(global as any).obtainToken = obtainToken;
(global as any).obtainCurrentSettings = obtainCurrentSettings;

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/inquiry">
          <Route path="/inquiry/:docname/:userid">
            <RedirectController redirect={false} forceLogin={false} UnloggedView={<Inquiry />}>
              <Inquiry />
            </RedirectController>
          </Route>
        </Route>
        {/* with frame */}
        <Route path="/">
          <MainStaticContainer>
            <Switch>
              <Route path="/model">
                <Route path="/model/:id">
                  <ContainersConfiguration />
                </Route>
              </Route>
              <Route path="/b2b">
                {/* <div className="App"> */}
                <RedirectController redirect={false} forceLogin={true} minimumPrivilage={userRoles.custommer}>
                  <HeaderContainer darkfont={true}>
                    <NavigationHeader />
                    <LanguageController />
                    <LogOutButton />
                  </HeaderContainer>
                  <UserTableController />
                </RedirectController>
                {/* </div> */}
              </Route>
              <Route path="/settings">
                {/* <div className="App"> */}
                <RedirectController redirect={false} forceLogin={true} minimumPrivilage={userRoles.administrator}>
                  <HeaderContainer darkfont={true}>
                    <NavigationHeader />
                    <LanguageController />
                    <LogOutButton />
                  </HeaderContainer>
                  <SettingView />
                </RedirectController>
                {/* </div> */}
              </Route>
              <Route path="/project">
                <RedirectController redirect={false} NoPrivilageView={<NoPriviliges forceLogin={false}></NoPriviliges>} forceLogin={true} minimumPrivilage={userRoles.custommer}>
                  <UnityProject>
                    <HeaderContainer darkfont={true}>
                      <ConfiguratorHeader />
                      <NavigationHeader />
                      <LanguageController />
                      <LogOutButton />
                    </HeaderContainer>
                  </UnityProject>
                </RedirectController>
              </Route>
              <Route path="/setup">
                <RedirectController redirect={false} NoPrivilageView={<NoPriviliges forceLogin={false}></NoPriviliges>} forceLogin={true} minimumPrivilage={userRoles.custommer}>
                  <SetupView>
                    <HeaderContainer darkfont={false}>
                      <NavigationHeader />
                      <LanguageController />
                      <LogOutButton />
                    </HeaderContainer>
                  </SetupView>
                </RedirectController>
              </Route>
              <Route path="/starting">
                <RedirectController forceLogin={true} minimumPrivilage={userRoles.guest}>
                  <StartingSchema>
                    <HeaderContainer darkfont={true}>
                      <NavigationHeader />
                      <LanguageController />
                      <LogOutButton />
                    </HeaderContainer>
                  </StartingSchema>
                </RedirectController>
              </Route>
              <Route path="/unlogged">
                <RedirectController
                  // UnloggedView={
                  //   <UnityProject appType={UserAppType.bezlogowania}>
                  //     <HeaderContainer darkfont={true}>
                  //       <NavigationHeader />
                  //       <LanguageController />
                  //       <LogOutButton />
                  //     </HeaderContainer>
                  //   </UnityProject>
                  // }
                  UnloggedView={
                    <StartingSchema>
                    <HeaderContainer darkfont={true}>
                      <NavigationHeader />
                      <LanguageController />
                      <LogOutButton />
                    </HeaderContainer>
                  </StartingSchema>
                  }
                  redirect={false}
                  forceLogin={false}
                >
                  <Redirect to="main" />
                </RedirectController>
              </Route>
              <Route path="/unloggedUnity">
                <RedirectController
                  UnloggedView={
                    <UnityProject appType={UserAppType.bezlogowania}>
                      <HeaderContainer darkfont={true}>
                        <NavigationHeader />
                        <LanguageController />
                        <LogOutButton />
                      </HeaderContainer>
                    </UnityProject>
                  }
                  redirect={false}
                  forceLogin={false}
                >
                  <Redirect to="main" />
                </RedirectController>
              </Route>
              <Route path="/unlogged">
                <UnityProject>
                  <HeaderContainer darkfont={true}>
                    <ConfiguratorHeader />
                    <NavigationHeader />
                    <LanguageController />
                    <LogOutButton />
                  </HeaderContainer>
                </UnityProject>
              </Route>
              <Route path="/inquires/:id?">
                <RedirectController redirect={false} forceLogin={true} minimumPrivilage={userRoles.guest}>
                  <HeaderContainer darkfont={true}>
                    <NavigationHeader />
                    <LanguageController />
                    <LogOutButton />
                  </HeaderContainer>
                  <InquiriesTableController />
                </RedirectController>
              </Route>
              <Route path="/saves">
                <RedirectController forceLogin={true} minimumPrivilage={userRoles.guest}>
                  <ProjectSaveView>
                    <HeaderContainer darkfont={true}>
                      <NavigationHeader />
                      <LanguageController />
                      <LogOutButton />
                    </HeaderContainer>
                  </ProjectSaveView>
                </RedirectController>
              </Route>

              <Route path="/login">
                <LoginView>
                  <HeaderContainer>
                    <LanguageController />
                    <div style={{ width: '4.5vmin' }} />
                  </HeaderContainer>
                </LoginView>
              </Route>
              <Route path="/main">
                <MainView forceLogin={true}>
                  <HeaderContainer>
                    <NavigationHeader />
                    <LanguageController />
                    <LogOutButton />
                  </HeaderContainer>
                </MainView>
              </Route>
              <Route path="/">
                <MainView forceLogin={false} redirect={true}>
                  <HeaderContainer>
                    <NavigationHeader />
                    <LanguageController />
                    <LogOutButton />
                  </HeaderContainer>
                </MainView>
              </Route>
            </Switch>
            <FooterBasic />
          </MainStaticContainer>
        </Route>
      </Switch>
    </Router >
  );
}
// }

// fetchUserData()
// .then((user) =>{
//   if (!user?.logged){
//     user = new UserSession(false, null);
//   }
//   if (process.env.NODE_ENV === 'development') console.log(JSON.stringify(user))
//   return JSON.stringify(user);
// })
// .catch(err => {
//   console.error(err);
//   return JSON.stringify(new UserSession(false, null));
// })

// obtainToken()
// .then(token =>{
//   if (process.env.NODE_ENV === 'development') console.log(token)
//   if (token && token.length > 1){
//     return token;
//   }
//   return "";
// })
// .catch(err => {
//   console.error(err);
//   return "";
// })
// console.log(process.env.REACT_APP_CLIENT_ID);

export default App;
