import React, { useState, useReducer, useCallback, ChangeEvent, useEffect } from 'react';
import style from './SetupView.module.scss';
import { ReactComponent as LogoModular } from 'src/assets/images/MODULAR_logo.svg';
import ButtonTransparent from 'src/components/ButtonTransparent';
import { Trans, useTranslation } from 'react-i18next';
import {
  ProjectStartingConfiguration,
  SelectableParameterArray,
  ModuleWidthType,
  SelectionEnums,
  SelectableParameterArrayString,
} from 'src/models/SetupViewModel';
import { setMainSettings } from 'src/utilities/obtainCurrentSettings';
import { useHistory, useLocation } from 'react-router-dom';
import testFrame from 'src/decisionTrees/frameDecisionTree';
import ISessionParams from 'src/interfaces/ISessionParams';

interface IPropsSelector {
  title: string;
  options: SelectionEnums;
  selectedId: number;
  onClick: (num: number) => void;
}
const ModuleSelector = (props: IPropsSelector) => {
  const selectionEnums: string[] = (props.options as unknown) as string[];
  const { t, i18n } = useTranslation();
  return (
    <div className={style.buttonHolder}>
      <label className={style.btnTitle}>{t(props.title)}</label>
      {selectionEnums.map((elem, index) => (
        <button
          key={elem}
          onClick={(e) => {
            e.preventDefault();
            props.onClick(index);
          }}
          className={style.btnSelectable}
          style={{
            ...(index === props.selectedId ? { backgroundColor: '#cc1a20', color: 'white' } : {}),
            ...(elem.length > 10 ? { fontSize: '1em' } : {}),
          }}
        >
          {t(elem)}
        </button>
      ))}
    </div>
  );
};

const initialState = new ProjectStartingConfiguration();

// function reducer(
//   state: ProjectStartingConfiguration,
//   nState: Partial<ProjectStartingConfiguration>,
// ): ProjectStartingConfiguration {
//   const mergedState = {
//     ...state,
//     ...nState,
//   };
//   const artState = testFrame(mergedState, wideEnabled);
//   return artState;
// }

interface IProps extends Partial<ISessionParams> {
  children?: React.ReactNode; // header
}
const SetupView = (props: IProps) => {
  const [isMobilbox, setIsMobilbox] = useState(false);
  const [isModular, setIsModular] = useState(false);

  // useEffect(() => {
  //   setWideEnabled(props?.backendUser?.company.includes('mobilbox') as boolean);
  // }, [props?.backendUser?.company])
  let history = useHistory();
  const search = useLocation().search;
  const { t, i18n } = useTranslation();
  const reducer = useCallback(
    (state: ProjectStartingConfiguration,
      nState: Partial<ProjectStartingConfiguration>) => {
  const mergedState = {
    ...state,
    ...nState,
  };
  const artState = testFrame(mergedState, isMobilbox, isModular);
  return artState;
    },
    [isMobilbox, isModular],
  )
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    // console.log(props?.backendUser?.email && !props?.backendUser?.email.includes('janusz'));
    const isMobi = props?.backendUser?.companyGroup ? props?.backendUser?.companyGroup?.toLowerCase().includes('obilbox') :
     props?.backendUser?.company.toLowerCase().includes('obilbox') as boolean; 
     console.log(isMobi);
    setIsMobilbox(isMobi);
    setIsModular(props?.backendUser?.company.includes('odular') as boolean);
    dispatch({ModuleWidth: new SelectableParameterArrayString(['2438 mm'])})
  }, [props?.backendUser?.company, dispatch])
  return (
    <div className={style.main__view__setup}>
      {props.children}
      <form className={style.formHolder}>
        <LogoModular className={style.FirstArticleBaseLogo} color="white" />
        <div className={style.inputHolder}>
          <label>
            {t('project name')}
            <div>
              <textarea
                value={state.ProjectTitle.getDtoValue.call(state.ProjectTitle)}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
                  dispatch({ ProjectTitle: { ...state.ProjectTitle, stringInput: event.target.value } })
                }
                wrap="soft"
                placeholder={t('insert title')}
              />
              <div className={style.inputBlock} />
            </div>
          </label>
        </div>
        <div className={style.selectionHolderWrapper}>
          <div className={style.selectionHolder}>
            <p className={style.selectionHolderTitle}>{t('chosen parameters')}</p>
            {Object.keys(state)
              .filter((key) => {
                return typeof state[key]?.['options'] !== 'undefined' && state[key]?.['options']?.length>0;
              })
              .map((key) => {
                const value = state[key] as SelectableParameterArray<string | boolean>;
                return (
                  <ModuleSelector
                    key={key}
                    onClick={(num: number) => dispatch({ [key]: { ...value, selectedValue: num } })}
                    title={key}
                    options={value.options}
                    selectedId={value.selectedValue}
                  />
                );
              })}
          </div>
          <button
            onClick={() => {
              setMainSettings(state.getDto());

              history.push(`/starting${search}`);
            }}
            className={`${style.btnSelectable} ${style.buttonForward}`}
          >
            {t('make project')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SetupView;
