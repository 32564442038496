import { authProvider } from 'src/authProvider';
import obtainToken from 'src/utilities/obtainToken';
// https://www.onesixsolutions.com/blog/app-modernization/how-to-integrate-azure-ad-into-your-web-application/
interface SessionPayload {
  '@odata.context': string;
  displayName: string | null;
  companyName: string | null;
  mail: string | null;
}

export class UserSession {
  logged: boolean;
  payload: SessionPayload | null;
  fromCache: boolean;
  constructor(logged: boolean, payload: SessionPayload | null, fromCache?: boolean) {
    this.logged = logged;
    this.payload = payload;
    this.fromCache = fromCache || false;
  }
}
let memoUserSession: UserSession = new UserSession(false, null);

let memoToken: string | undefined;

export const fetchUserData = async (): Promise<UserSession> => {
  const obtainedToken = await obtainToken(['user.read']);
  if (!obtainedToken) {
    console.log('COULDNT GET TOKEN - check adblock or other blocking soft');
    return new UserSession(false, null);
  }
  // memo
  if (memoUserSession?.logged === true &&  memoToken === obtainedToken){
    memoUserSession.fromCache = true;
    return memoUserSession;
  }
  try {
    const res = await fetch('https://graph.microsoft.com/v1.0/me?$select=companyName,displayName,givenName,id,jobTitle,mail,surname,mobilePhone', {
    // const res = await fetch('https://graph.microsoft.com/v1.0/me', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + obtainedToken,
      },
    });
    const jsonObject = await res.json();
    const userSession = new UserSession(true, {...jsonObject})
    process.env.NODE_ENV === 'development' && console.log('userData: ', userSession);

    // store for memo
    memoUserSession = userSession;
    memoToken = obtainedToken;
    
    return userSession;
  } catch (error) {
    console.log(error);
    return new UserSession(false, null);;
  }
};

/**obsolete - test only*/
export const obtainUserDataNew = async (token: string) => {
  const userRequest = {
    scopes: [process.env.REACT_APP_API_SCOPE as string],
  };
  try {
    const response = await authProvider.acquireTokenSilent(userRequest);
    const res = await fetchAndPrint(response.accessToken, 'https://localhost:5012/version/safe');
    return res;
  } catch (error) {
    if (error.name === 'InteractionRequiredAuthError') {
      try {
        const response = await authProvider.acquireTokenPopup(userRequest);
        const res = await fetchAndPrint(response.accessToken, 'https://localhost:5012/version/safe');
        return res;
      } catch (err) {
        console.log(err);
        return err;
      }
    }
    return error;
  }
};
/**obsolete - test only*/
const fetchAndPrint = async (token: string, url: string) => {
  const res = await fetch('https://localhost:5012/version/safe', {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
  });
  const jsonObject = await res.json();
  return jsonObject;
};

/**obsolete - test only*/
const obtainUserDataOld = async () => {
  authProvider.getAccount();
  if (authProvider.getAccount()) {
    var tokenRequest = authProvider.getAuthenticationParameters();
    authProvider
      .acquireTokenSilent({ scopes: ['user.read'] })
      .then((response) => {
        var headers = new Headers();
        var bearer = 'Bearer ' + response.accessToken;
        headers.append('Authorization', bearer);
        var options = {
          method: 'GET',
          headers: headers,
        };
        var graphEndpoint = 'https://graph.microsoft.com/v1.0/me?$select=companyName,displayName,mail';
        return fetch(graphEndpoint, options);
      })
      .then((resp) => {
        return resp.json();
        //do something with response
      })
      .then((json) => console.log(json))
      .catch((err) => {
        // could also check if err instance of InteractionRequiredAuthError if you can import the class.
        if (err.name === 'InteractionRequiredAuthError') {
          return authProvider
            .acquireTokenPopup(tokenRequest)
            .then((response) => {
              console.log(response);
              
              // get access token from response
              // response.accessToken
            })
            .catch((err) => {
              console.log(err);
              // handle error
            });
        }
      });
  } else {
    // user is not logged in, you will need to log them in to acquire a token
  }
};

export default obtainUserDataOld;
