import React from 'react';
import style from './NavigationHeader.module.scss';
import ButtonTransparent from 'src/components/ButtonTransparent';
import { useTranslation } from 'react-i18next';
import { NavLink, Redirect } from 'react-router-dom';
import ISessionParams from 'src/interfaces/ISessionParams';
import userRoles from 'src/enums/userRoles';
import {SessionContext} from 'src/components/RedirectController';

interface IProps {
  darkfont?: boolean;
}
const pages = [
  ['', 'main page'],
  ['saves', 'projects'],
  ['b2b', 'B2B'],
];

const adminsOnlyPages = [
  ['settings', 'ustawienia']
]

const NavigationHeader = (props: IProps) => {
  const sessionParams = React.useContext<ISessionParams | null> (SessionContext)
  const { t } = useTranslation();
  let arr = pages;
  const currentRole  = (sessionParams?.backendUser?.role as number);
  if (currentRole >= userRoles.administrator){
    arr = [...arr, ...adminsOnlyPages];
  }
  return (
    <>
      {arr.map((val) => (
        < ButtonTransparent key={val[0]}>
          <NavLink
            to={`/${val[0]}`}
            className={style.header_button_basic}
            activeClassName={style.header_button_selected}
          >
            <span>{t(val[1])}</span>
          </NavLink>
        </ButtonTransparent>
      ))}
    </>
  );
};
export default NavigationHeader;
