import React, { useCallback } from 'react';
import './SecArticleBase.scss';
import style from './SecArticleMain.module.scss';

interface IProps {}

const SecArticleMain = (props: IProps) => {
  return (
    <article className={`SecArticleBase ${style.SecArticleMain}`}></article>
  );
};

export default SecArticleMain;
