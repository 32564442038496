import {
  ContainerModel,
  Containers,
  ContainerType,
} from '../../models/ContainerModel';
interface IProps {
  containerModel: ContainerModel;
  containers: Containers;
}

interface IDisplayDescription {
  id: string;
  operation: string;
  variantShort: string;
  comments: string;
}

export class containerElement implements IDisplayDescription {
  constructor(data: IDisplayDescription) {
    this.id = data.id;
    this.operation = data.operation;
    this.variantShort = data.variantShort;
    this.comments = data.comments;
  }
  id: string;
  operation: string;
  variantShort: string;
  comments: string;
}

const externalWallCodes = ['a', 'b', 'c', 'd'];

export function containerElementsFactory({
  containerModel,
  containers,
}: IProps): Array<containerElement> {
  const panelIsolation = containers.getQuestionByName(
    containerModel.Ctype === ContainerType.Sanitarny
      ? 'Sanitarne - Izolacja w panelach'
      : 'Izolacja w ścianach paneli: ',
  );
  const containerElements: Array<containerElement> = [];
  containerElements.push(
    new containerElement({
      id: 'Konstrukcja ramy',
      operation: 'Operacja 1',
      variantShort: containerModel.GetVariantType(),
      comments: containerModel.AdditionDescription,
    }),
  );
  containerElements.push(
    new containerElement({
      id: 'Kolor ramy',
      operation: 'Operacja 4',
      variantShort: containers.getQuestionByName('Kolor ramy:'),
      comments: '',
    }),
  );
  containerElements.push(
    new containerElement({
      id: 'Kolor okładziny',
      operation: 'Operacja 8',
      variantShort: containers.getQuestionByName(
        'Kolor okładziny zewnętrznej:',
      ),
      comments: '',
    }),
  );
  containerModel.WallArray.forEach((wall, wallIndex) => {
    const segmentes = wall.Segments;
    const wallCode = externalWallCodes[wallIndex];
    segmentes.forEach((segment, segmentIndex) => {
      const segmentCode = segmentIndex + 1;
      const CE = segment.getPanelType(
        `${wallCode}${segmentCode}`,
        'Operacja 8',
        panelIsolation,
      );
      CE && containerElements.push(CE);
    });
  });
  containerModel.InteriorWalls.forEach((wall, wallIndex) => {
    const segmentes = wall.Segments;
    const wallCode = wall.Vertical ? 'v' : 'h';
    const wallNumber = containerModel.InteriorWalls.filter(
      (s) => s.ID < wallIndex && s.Vertical === wall.Vertical,
    ).length;
    segmentes.forEach((segment, segmentIndex) => {
      const segmentCode = segmentIndex + 1;
      const CE = segment.getPanelType(
        `${wallCode}${wallNumber + 1}.${segmentCode}`,
        'Operacja 9',
        'Wewnętrzny',
      );
      CE && containerElements.push(CE);
    });
  });

  containerElements.push(
    new containerElement({
      id: 'P1',
      operation: 'Operacja 5',
      variantShort: containers.getQuestionByName(
        containerModel.Ctype === ContainerType.Sanitarny
          ? 'Sanitarne - Izolacja w podlodze: '
          : 'Izolacja w podlodze:',
      ),
      comments: '',
    }),
  );

  containerElements.push(
    new containerElement({
      id: 'S1',
      operation: 'Operacja 6',
      variantShort: containers.getQuestionByName(
        containerModel.Ctype === ContainerType.Sanitarny
          ? 'Sanitarne - Izolacja w dachu:'
          : 'Izolacja w dachu:',
      ),
      comments: '',
    }),
  );
  return containerElements;
}
