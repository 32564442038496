import { containerElement } from '../controllers/containersConfigs/elementsFactory';
import * as elementsDictionary from '../models/PanelGeneralDictionary';

export type Int = number;

export class AnyWall {}

enum UserAppType {
  standard = 0,
  bezlogowania = 1,
  wymiar6x3 = 2,
}

export interface IContainersQueryShort extends Object {
  docName: string;
  imageUrl: string;
  Containers: string;
  saveTime: string;
  title: string;
  userAAD: string;
  userName: string;
  projectName: string;
}

export class ContainersQuery implements IContainersQueryShort {
  docName!: string;
  projectName!: string;
  imageUrl!: string;
  payload!: Containers;
  Containers!: string;
  saveTime!: string;
  title!: string;
  userAAD!: string;
  userName!: string;
  serializable: object;
  [key: string]: any;

  constructor() {
    this.serializable = {
      payload: Containers,
    };
  }
}

export class Containers {
  SaveTime?: string;
  AppRevision?: number;
  containers!: ContainerModel[];
  cQuestionAndAnswear?: QuestionsAndAnswears;
  ProjectTitle?: string;
  AppType?: UserAppType;
  UUID?: string;

  // added
  TotalPrice?: number;

  serializable: object;
  [key: string]: any;

  constructor() {
    this.serializable = {
      containers: [ContainerModel],
    };
  }
  getQuestionByName(questionText: string): string {
    const question = this.cQuestionAndAnswear?.questionAndAnswear.find(
      (elem: { QT: string }) => elem.QT === questionText,
    );
    return question?.AT || 'brak danych';
  }
}

export class QuestionsAndAnswears {
  questionAndAnswear!: QuestionAndAnswear[];
  getQuestionByName(questionText: string): QuestionAndAnswear | undefined {
    return this.questionAndAnswear.find((elem: { QI: string }) => elem.QI.includes(questionText));
  }
  private generateUnfoundQestion(_QT: string, _QN: number) {
    return { QN: _QN, QT: _QT, QI: _QT, QS: '', AT: 'brak danych', AS: '' };
  }
  getQuestionsByArray(questionTexts: string[]): QuestionAndAnswear[] {
    const accumulator: QuestionAndAnswear[] = [];

    questionTexts.forEach((value, index) => {
      const fullQuestion = this.getQuestionByName(value);
      if (fullQuestion) {
        accumulator.push(fullQuestion);
      } else {
        accumulator.push(this.generateUnfoundQestion(value, index + 1));
      }
    });
    return accumulator;
  }
}

export interface QuestionAndAnswear {
  QN: number;
  QT: string;
  QI: string;
  QS: string;
  AT: string;
  AS: string;
}

export class ContainerModel {
  AdditionDescription!: string;
  BackImage?: any;
  ContainerPrefab?: any;
  ContainerSize!: { x: number; y: number };
  ContainerVariants!: Array<ContainerVariant>;
  Ctype!: ContainerType;
  Disp?: Int;
  Floor!: Int;
  Hide?: boolean;
  ID!: Int; // in place of name
  // in place of name
  InteriorWalls!: Array<WallExpanded>;
  IsRotated!: boolean;
  Mirrored!: boolean;
  MyContainerController?: any;
  Nr!: Int;
  Ord?: Int;
  PlacementPoint!: { x: number; y: number };
  Ref!: Int;
  Variant!: Int;
  WallArray!: Array<Wall>;
  WorkTitle!: string;
  UUID?: string;
  serializable: object;

  [key: string]: any;

  constructor() {
    this.serializable = {
      ContainerVariants: [ContainerVariant],
      InteriorWalls: [WallExpanded],
      WallArray: [Wall],
    };
  }

  GetCurrentVariant(): ContainerVariant {
    if (this.Variant < this.ContainerVariants.length) {
      return this.ContainerVariants[this.Variant];
    } else {
      return this.ContainerVariants[this.ContainerVariants.length - 1];
    }
  }
  GetVariantType(): string {
    const tempName = this.GetCurrentVariant().Title;
    return tempName.toLowerCase().includes('eco') ? 'ECO' : tempName.toLowerCase().includes('sr') ? 'SR' : 'MB';
  }
}

export enum ContainerType {
  Biurowy = 0,
  Sanitarny = 1,
  KlatkaSchodowa = 2,
  Obrocony = 33,
  Foremny = 44,
}

export class ContainerVariant {
  Title!: string;
  LongTitle!: string;
  RecordName!: string;
  /**obsolete! */
  /**obsolete! */
  Price!: number;
  /**obsolete! */
  /**obsolete! */
  StartPrice!: number; // obsolete
  // obsolete
  MPrice!: number;
  /**obsolete! */
  /**obsolete! */
  AlgecoPrice!: number;
  /**obsolete! */
  /**obsolete! */
  CombrosPrice!: number;
  ThumbnailImage: any;
  AddImage: any;
  //ExcludedMde: BasicCompanyVariants[];
}

export class Wall {
  ID!: number;
  //inject durring creation
  //inject durring creation
  AdditionDescription!: string;

  MyWallController: any;
  Segments!: Array<WallModule>;

  [key: string]: any;

  constructor() {
    this.serializable = {
      Segments: [WallModule],
    };
  }
}

export class WallExpanded extends Wall {
  Vertical!: boolean;
  Length!: number;
  StartingPoint: any;
  [key: string]: any;
}

export class WallModule {
  ID!: number;
  AdditionDescription!: string;

  MyWallModuleController: any; // obsolete
  MLength!: number;
  Blocked!: boolean;
  /**construction element after */
  /**construction element after */
  CEAfter!: boolean;
  sOpens!: OpeningType[]; // useless
  // useless

  InfDetails!: boolean;
  IsRemoved!: boolean;
  CorridorBehaviour!: boolean; //static
  //static
  Priority!: number;
  OpeningList!: BaseOpening[];

  AllowedFurnitures!: FurnitureType[];
  FDL!: any[]; // useless
  FurnitureList!: FurnitureElem[];
  [key: string]: any;
  constructor() {
    this.serializable = {
      FurnitureList: [FurnitureElem],
      OpeningList: [BaseOpening],
    };
  }
  getPanelType = (id: string, operation: string, variantShort: string): containerElement | null => {
    if (this.IsRemoved) {
      return null;
    }
    let changes = elementsDictionary.normalDescription.longText;
    if (this.OpeningList.some((s) => s.OpenType === OpeningType.BigWindow)) {
      changes = elementsDictionary.windowDescription.longText;
    } else if (this.OpeningList.some((s) => s.OpenType === OpeningType.ServeWindow)) {
      changes = elementsDictionary.windowServeDescription.longText;
    } else if (this.OpeningList.some((s) => s.OpenType === OpeningType.EmptyOpening)) {
      changes = elementsDictionary.emptyOpeningDescription.longText;
    } else if (this.OpeningList.some((s) => s.OpenType === OpeningType.PanoramaWindow)) {
      changes = elementsDictionary.windowPanoramaDescription.longText;
    } else if (this.OpeningList.some((s) => s.OpenType === OpeningType.GlassDoor)) {
      changes = elementsDictionary.doorGlassDescription.longText;
    } else if (this.OpeningList.some((s) => s.OpenType === OpeningType.DoubleDoor)) {
      changes = elementsDictionary.doorDoubleDescription.longText;
    } else if (this.OpeningList.length > 0) {
      changes = elementsDictionary.windowSanitDescription.longText;
    }

    // if (this.OpeningList.some((s) => s.amIDoor())) {
    //   changes = elementsDictionary.doorDescription.longText;
    // } else if (this.OpeningList.length > 0) {
    //   changes = elementsDictionary.windowDescription.longText;
    // }

    if (this.FurnitureList.some((s) => s.amISwitch())) {
      changes += elementsDictionary.switchDescription.longText;
    }
    if (this.FurnitureList.some((s) => s.amISocket())) {
      changes += elementsDictionary.socketDescription.longText;
    }

    return new containerElement({
      id,
      operation,
      variantShort: `${variantShort} ${changes}`,
      comments: this.AdditionDescription,
    });
  };
}

export enum OpeningType {
  None = 0,
  EmptyOpening = 10,
  Door = 20,
  sDoor = 21,
  BigWindow = 30,
  ServeWindow = 31,
  SmallWindowLeft = 40,
  SmallWindowCenter = 50,
  SmallWindowRight = 60,
  SmallWindowOutLeft = 70,
  PanoramaWindow = 80,
  DoubleDoor = 90,
  GlassDoor = 100,
}

export class BaseOpening {
  AdditionDescription!: string;
  MyOpeningController: any;
  OpenType!: OpeningType;
  Inside!: boolean;
  Right!: boolean;
  RoletaZew!: boolean;
  DefinedSize!: number;
  DefinedPosition!: number;
  amIWindow = () => this.OpenType >= 30 && this.OpenType <= 89;
  amIDoor = () => !this.amIWindow();
}

export enum FurnitureType {
  Kitchen = 1,
  RadiatorAny = 2,
  Radiator2kw = 3,
  Radiator1kw = 4,
  Radiator05kw = 5,
  Sink = 6,
  Socket = 7,
  SocketLeft = 8,
  SocketCenter = 9,
  SocketRight = 10,
  Switch = 11,
  SwitchLeft = 12,
  SwitchCenter = 13,
  SwitchRight = 14,
}

export class FurnitureElem {
  AdditionDescription!: string;
  MyFurnitureType!: FurnitureType;
  MyFurnitureElemController: any;
  PlacementAngle: any;
  PlacementPoint: any;
  PlacementScale: any;
  PrefabPosition: any;
  amISocket = () => this.MyFurnitureType >= 7 && this.MyFurnitureType <= 10;
  amISwitch = () => this.MyFurnitureType >= 11 && this.MyFurnitureType <= 14;
}
