import {IConfigurationSchemaDTO} from './ConfigurationSchema';

export interface ISelectableParameter {
  options: SelectionEnums;
  selectedValue: number;
  [key: string]: any;
}
abstract class SelectableParameter<T> extends Object {
  getDtoValue!: () => T;
}
export abstract class SelectableParameterArray<T> extends SelectableParameter<T> implements ISelectableParameter {
  options!: SelectionEnums;
  selectedValue!: number;
  [key: string]: any;
  constructor(options: SelectionEnums, selectedValue = 0) {
    super();
    this.options = options;
    this.selectedValue = selectedValue;
  }
}
const clamp = (val: number, min: number, max: number): number => {
  return Math.min(Math.max(val, min), max);
};

export class SelectableParameterArrayString extends SelectableParameterArray<string> implements ISelectableParameter {
  getDtoValue = function (this: SelectableParameterArrayString): string {
    const id = clamp(this.selectedValue, 0, this.options.length - 1);
    return this.options[id];
  };
}

export class SelectableParameterBool extends SelectableParameterArray<boolean> {
  getDtoValue = function (this: SelectableParameterBool) {
    const id = clamp(this.selectedValue, 0, this.options.length - 1);
    return this.options[id] === 'TAK';
  };
}

export class SelectableParameterString extends SelectableParameter<string> {
  stringInput!: string;
  getDtoValue = function (this: SelectableParameterString) {
    return this.stringInput ? this.stringInput : '';
  };
}
export class SelectableParameterEnum extends SelectableParameter<FrameType> {
  stringInput!: FrameType;
  getDtoValue = function (this: SelectableParameterArrayString) {
    return this.stringInput ? this.stringInput : '';
  };
  constructor(val: FrameType) {
    super();
    this.stringInput = val;
  }
}

const cModuleLength = ['6058 mm'] as const;
export type ModuleLengthType = typeof cModuleLength[number];
const ModuleLength: ModuleLengthType[] = cModuleLength as unknown as ModuleLengthType[];

// const cModuleWidth = ['2438 mm', '2990 mm'] as const;
const cModuleWidth = ['2438 mm', '2990 mm'] as const;
export type ModuleWidthType = typeof cModuleWidth[number];

const ModuleWidth: ModuleWidthType[] = cModuleWidth as unknown as ModuleWidthType[];

// export const cModuleHeight = ['2800 mm', '2591 mm'] as const;
export const cModuleHeight = ['2800 mm'] as const;
export type ModuleHeightType = typeof cModuleHeight[number];
export const ModuleHeight: ModuleHeightType[] = cModuleHeight as unknown as ModuleHeightType[];

const cManyFloors = ['TAK', 'NIE'] as const;
export type ManyFloorsType = typeof cManyFloors[number];
export const ManyFloors: ManyFloorsType[] = cManyFloors as unknown as ManyFloorsType[];

const cDrainage = ['perimeter gutter', 'through walls'] as const;
export type DrainageType = typeof cDrainage[number];
const Drainage: DrainageType[] = cDrainage as unknown as DrainageType[];

const cCurrency = ['PLN', 'EUR'] as const;
export type CurrencyType = typeof cCurrency[number];
const Currency: CurrencyType[] = cCurrency as unknown as CurrencyType[];


// export type SelectionEnums = typeof ModuleLength | typeof ModuleWidth | 
// typeof ModuleHeight | typeof ManyFloors | typeof Drainage;
export type SelectionEnums = Array<ModuleLengthType | ModuleWidthType | ModuleHeightType | ManyFloorsType | DrainageType | CurrencyType>
export class ProjectStartingConfiguration {
  ProjectTitle!: SelectableParameterString;
  ModuleLength!: SelectableParameterArrayString;
  ModuleWidth!: SelectableParameterArrayString;
  ModuleHeight!: SelectableParameterArrayString;
  ManyFloors!: SelectableParameterBool;
  Drainage!: SelectableParameterArrayString;
  Frame!: SelectableParameterEnum;
  Currency!: SelectableParameterArrayString;
  [key: string]: any;
  constructor() {
    this.ProjectTitle = new SelectableParameterString();
    this.ModuleLength = new SelectableParameterArrayString(ModuleLength);
    this.ModuleWidth = new SelectableParameterArrayString(ModuleWidth);
    this.ModuleHeight = new SelectableParameterArrayString(ModuleHeight);
    this.ManyFloors = new SelectableParameterBool(ManyFloors);
    this.Drainage = new SelectableParameterArrayString(Drainage);
    this.Frame = new SelectableParameterEnum('MB');
    this.Currency = new SelectableParameterArrayString(Currency);
  }


  getDto = function (this: ProjectStartingConfiguration): ProjectStartingConfigurationDto {
    const nObj = Object.entries(this)
      .filter((keyValueArr: [string, SelectableParameterArray<string | boolean>], index) => {
        const [key, value] = keyValueArr;
        return value.hasOwnProperty('getDtoValue');
      })
      .map((keyValueArr: [string, SelectableParameterArray<string | boolean>], index) => {
        const [key, value] = keyValueArr;
        return [key, value.getDtoValue.call(value)];
      });
    return Object.fromEntries(nObj);
  };
  copyValues = function (this: ProjectStartingConfiguration, old: ProjectStartingConfiguration) {
    const nObj = Object.entries(this)
      .filter((keyValueArr: [string, SelectableParameterArray<string | boolean>], index) => {
        const [key, value] = keyValueArr;
        return value.hasOwnProperty('getDtoValue');
      })
      .forEach((keyValueArr: [string, SelectableParameterArray<string | boolean>], index) => {
        const [key, value] = keyValueArr;
        if (value.hasOwnProperty('selectedValue')){
          value.selectedValue = clamp(old[key].selectedValue, 0, value.options.length - 1);
        }
        if (value.hasOwnProperty('stringInput')){
          value.stringInput = old[key].stringInput;
        }
        // return [key, {...value, selectedValue: old[key].selectedValue, stringInput: old[key].stringInput} ];
      });
    // return Object.fromEntries(nObj);
  }
  // filterAnswears = function (this: ProjectStartingConfiguration)
}


export interface StartingConfigurationDto {
  ProjectTitle: string;
  ModuleLength: string;
  ModuleWidth: string;
  ModuleHeight: string;
  ManyFloors: boolean;
  Drainage: string;
  Frame: string;
}
export class ProjectStartingConfigurationDto implements StartingConfigurationDto, IConfigurationSchemaDTO {
  totalRow!: number;
  totalColumns!: number;
  rows!: number;
  columns!: number;
  corridor!: boolean;
  ProjectTitle!: string;
  ModuleLength!: string;
  ModuleWidth!: string;
  ModuleHeight!: string;
  ManyFloors!: boolean;
  Drainage!: string;
  Frame!: string;
}

export type FrameType = 'SR' | 'MB' | 'ECO' | 'ECO_S355' | 'F3x6';
