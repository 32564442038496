import React, { useState, useEffect } from 'react';
import style from './SchemaSelectorCorridorButton.module.scss';

interface IProps {}

const SchemaSelectorCorridorButton = (props: IProps) => {
  return (
    <div className={style.buttonHolder}>
      <div className={style.topElement}/>
      <div className={style.middleElement}/>
      <div className={style.topElement}/>
    </div>
  );
};

export default SchemaSelectorCorridorButton;
