

export const priceWithFixedDiggits = (num: number, currencyUsed?:number, plnMultiplayer?: number, maximumFractionDigits = 0, minimumFractionDigits = 0): string => 
{
    let currency = " zł";
    if (currencyUsed && plnMultiplayer && currencyUsed>50)
    {
        num = num * plnMultiplayer;
        currency = " eu";
    }
    return new Intl.NumberFormat('pl-PL', {maximumFractionDigits, minimumFractionDigits}).format(num) + currency
}