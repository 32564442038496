import React, { useState, useEffect, useRef, useCallback } from 'react';
import './FirstArticleBase.scss';
import './FirstArticleSaved.scss';
import { Trans, useTranslation } from 'react-i18next';
// import logo from 'src/assets/images/modular-logo-white.png';
import { ReactComponent as LogoModular } from '../../assets/images/MODULAR_logo.svg';
import ButtonTransparent from 'src/components/ButtonTransparent';
import IntegerSlider from 'src/components/IntegerSlider';

import { BasicProjectData, SliderData } from 'src/views/ProjectSaveView';

interface IProps {
  selectedProject: BasicProjectData | null;
}

const FirstArticleSaved = (props: IProps) => {
  const { t, i18n } = useTranslation();
  // const [floorNumer, setFloorNumer] = useState<number>(2);
  // const [containerNumer, setContainerNumer] = useState<number>(2);
  // const [nettoPrice, setNettoPrice] = useState<number>(2);
  const {selectedProject} = props;
  return (
    <article className="FirstArticleBase FirstArticleHolder">
      <div>
        <h1>{t('projects')}</h1>
        <h2>{t('with configurator')}</h2>
      </div>
      <div className="FirstArticleHolderSliders">
        {selectedProject &&
          Object.values(selectedProject).
          filter(elem => typeof elem === 'object')
          .map((elem: SliderData) => (
            <IntegerSlider
              key={elem.displayText}
              displayText={t(elem.displayText)}
              min={elem.minValue}
              max={elem.maxValue} // dynamic
              value={elem.value}
              setValue={elem.setValue}
            />
          ))}
      </div>
    </article>
  );
};

export default FirstArticleSaved;
