import obtainToken from 'src/utilities/obtainToken';


/**
 * @description This method is UNSAFE keep in try catch
 */
export const fetchProtectedRawUnsafe = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  const obtainedToken = await obtainToken([process.env.REACT_APP_API_SCOPE as string]);
  if (!obtainedToken) {
    // console.error('COULDNT GET TOKEN - check adblock or other blocking soft');
    console.log('COULDNT GET TOKEN - check adblock or other blocking soft');
    throw new Error('COULDNT GET TOKEN - check adblock or other blocking soft');
  }
  return await fetch(input, {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: 'Bearer ' + obtainedToken,
    },
  });
};


/**
 * @description The method is safe - errors are already cached
 */
export const fetchProtectedRaw = async (input: RequestInfo, init?: RequestInit): Promise<Response | undefined> => {
  const obtainedToken = await obtainToken([process.env.REACT_APP_API_SCOPE as string]);
  if (!obtainedToken) {
    // console.error('COULDNT GET TOKEN - check adblock or other blocking soft');
    console.log('COULDNT GET TOKEN - check adblock or other blocking soft');
    return undefined;
  }
  try {
    const res = await fetch(input, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: 'Bearer ' + obtainedToken,
      },
    });
    if (res.status > 399) {
      throw new Error(`response error - status: ${res.status} statusText: ${res.statusText}`);

    }
    return res;
  } catch (error) {
    console.log(error);
    console.error(error);
    return undefined;
  }
};

/**
 * @description The method is safe - errors are already cached
 */
export const fetchProtectedJson = async (input: RequestInfo, init?: RequestInit): Promise<any> => {
  const resp = await fetchProtectedRaw(input, {
    ...init,
    headers: { ...init?.headers, 'Content-Type': 'application/json' },
  });
  if (!resp) {
    console.error('COULDNT FETCH JSON');
    return undefined;
  }
  try {
    const jsonObject = await resp.json();
    return jsonObject;
  } catch (error) {
    console.log('COULDNT PARSE JSON');
    console.error('COULDNT PARSE JSON');
    return undefined;
  }
};

// process.env.NODE_ENV === "development" && 
if (process.env.NODE_ENV === "development") (global as any).fetchProtectedJson = fetchProtectedJson;

export default fetchProtectedJson;
