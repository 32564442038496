import React from 'react';
import style from './HeaderContainer.module.scss';
import ISessionParams from 'src/interfaces/ISessionParams';
interface IProps extends Partial<ISessionParams>{
  darkfont?: boolean;
  children?: React.ReactNode;
}

const HeaderContainer = (props: IProps) => {
  return (
    <div
      className={`${style.headerContainer} ${props.darkfont && style.darkfont}`}
    >
      {props.children }
    </div>
  );
};

export default HeaderContainer;
