import React, { CSSProperties, MouseEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import style from './ButtonFullfilled.module.scss';
interface IProps {
  onClick?: (event: MouseEvent) => void; // header
  children?: React.ReactNode; // header
  propStyle?: CSSProperties;
  propClass?: string;
}
const ButtonFullfilled = (props: IProps) => {
  return (
      <button style={props.propStyle} onClick={props.onClick} className={`${style.btnSelectable} ${style.buttonForward} ${props.propClass}`}>
        {props.children}
      </button>
  );
};

ButtonFullfilled.defaultProps = {
  onClick: () => {},
};
export default ButtonFullfilled;
