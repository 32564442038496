import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AzureAD, IAzureADFunctionProps, AuthenticationState } from 'react-aad-msal';
import { authProvider } from 'src/authProvider';
import userRoles from 'src/enums/userRoles';
import { host, userEndpoint } from 'src/models/urlEndpoints';
import fetchProtectedJson from 'src/utilities/fetchProtected';
import backendUser from 'src/interfaces/backendUser';
import ISessionParams from 'src/interfaces/ISessionParams';

export const SessionContext = React.createContext<ISessionParams | null>(null);

declare type StringDict = {
  [key: string]: string;
};
interface IProps {
  children: React.ReactNode;
  redirect?: boolean | undefined;
  forceLogin?: boolean | undefined;
  minimumPrivilage: userRoles;
  WaitingView?: React.ReactNode;
  UnloggedView?: React.ReactNode;
  NoPrivilageView?: React.ReactNode;
}

interface backendUserOptimised extends Partial<backendUser> { 
  role: userRoles | number;
}

let firstLogged = false;
/**
 * it has no sense to use redirect and force login - becouse you will be redireted right after login
 */
const RedirectController = (props: IProps) => {
  let history = useHistory();
  // const [role, setRole] = useState<userRoles | number> (0);
  const [bUser, setBUser] = useState<backendUserOptimised>({ role: 0 });
  const checkUserRole = useCallback(async () => {
    if (!firstLogged) {
      firstLogged = true;
      await fetchProtectedJson(`${host}/${userEndpoint}/login`, { method: 'PUT' });
    }
    const userRolesRaw = await fetchProtectedJson(`${host}/${userEndpoint}`);
    const user = userRolesRaw as backendUser | null;
    if (user) {
      setBUser(user);
      // setRole(user.role);
    }
  }, [setBUser, firstLogged]);
  const childrenWithPropsCalc = useCallback(
    (children: React.ReactNode, params: ISessionParams) =>
      React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, params);
        }
        return child;
      }),
    [],
  );

  return (
    <AzureAD provider={authProvider} forceLogin={props.forceLogin}>
      {(AzureADFunctionProps: IAzureADFunctionProps) => {
        const { login, logout, authenticationState, error, accountInfo } = AzureADFunctionProps;
        switch (authenticationState) {
          case AuthenticationState.Authenticated:
            if (bUser.role >= props.minimumPrivilage) {
              const sessionParams: ISessionParams = { accountInfo, backendUser: bUser as backendUser };
              const childrenWithProps = childrenWithPropsCalc(props.children, sessionParams);
              return (
                <SessionContext.Provider value={sessionParams}>
                  <> {childrenWithProps} </>
                </SessionContext.Provider>
              );
            } else if (bUser.role > 0.1) {
              return props.NoPrivilageView;
            } else if (bUser.role === 0) {
              checkUserRole();
              setBUser({ role: 1 });
            }
            return props.WaitingView;

          case AuthenticationState.Unauthenticated:
            props.redirect && history.push('/login');
            return props.UnloggedView;
          case AuthenticationState.InProgress:
            return props.WaitingView; // consider waiting screen?
        }
      }}
    </AzureAD>
  );
};
RedirectController.defaultProps = {
  minimumPrivilage: userRoles.guest,
  forceLogin: true,
  redirect: true,
  WaitingView: <p>oczekiwanie</p>,
  UnloggedView: <p>niezalogowany</p>,
  NoPrivilageView: <p>brak uprawnień</p>,
};

// const [logState, setLogState] = useState<AuthenticationState>(AuthenticationState.InProgress);

// useEffect(() => {
//   const checkunc = props.forceLogin ? obtainToken : checkSilently;
//   checkunc()
//     .then((res) => {
//       console.log(res);
//       res && res.length > 4
//         ? setLogState(AuthenticationState.Authenticated)
//         : setLogState(AuthenticationState.Unauthenticated);
//     })
//     .catch((error) => {
//       setLogState(AuthenticationState.Unauthenticated);
//     });
// }, []);

// switch (logState) {
//   case AuthenticationState.Authenticated:
//     console.log('bagno');
//     return <> {props.children} </>;
//   case AuthenticationState.Unauthenticated:
//     console.log(logState);
//     history.push('/login');
//     return null;
//   case AuthenticationState.InProgress:
//     return null; // consider waiting screen?
// }
export default RedirectController;
