import React from 'react';
import style from './ButtonTransparent.module.scss';
interface IProps {
  children: React.ReactNode;
  onClick?: () => void;
  propStyle?: React.CSSProperties | undefined;
}

const ButtonTransparent = (props: IProps) => {
  return (
    <button
      style={props.propStyle}
      onClick={props.onClick}
      className={style.btn}
    >
      {props.children}
    </button>
  );
};

export default ButtonTransparent;
