import { throws } from "assert";
import UserAppType from 'src/enums/appType';
import { ProjectStartingConfigurationDto, StartingConfigurationDto } from 'src/models/SetupViewModel';
import {IConfigurationSchemaDTO} from 'src/models/ConfigurationSchema';

interface ConstructorArgs{
    appType?: UserAppType;
    frontendVersion?: string;
    language?: string;
}
class SettingsObject implements ConstructorArgs, ProjectStartingConfigurationDto{
    appType: UserAppType = UserAppType.standard;
    frontendVersion: string = "1";
    Drainage!: string;
    ManyFloors!: boolean;
    ModuleHeight!: string;
    ModuleLength!: string;
    ModuleWidth!: string;
    ProjectTitle!: string;
    rows: number = -1;
    columns: number = -1;
    corridor: boolean = false;
    Frame!: string;
    totalRow!: number;
    totalColumns!: number;
    constructor(args: ConstructorArgs = {}){
        Object.assign(this, args);
    }
}

let settingsInstance: SettingsObject = new SettingsObject();
// settingsInstance = new SettingsObject({AppType: ApplicationTypeEnum.Oversize});

export const setCurrentSettings = (parameters: Required<ConstructorArgs>): void => {
    Object.assign(settingsInstance, parameters);
}
export const setMainSettings = (parameters: StartingConfigurationDto | IConfigurationSchemaDTO): void => {
    Object.assign(settingsInstance, parameters);
}
export const containsMainSettings = (): boolean => {
    return settingsInstance.Drainage !== undefined;

}
export const resetCurrentSettings = (): void => {
    settingsInstance = new SettingsObject();
}
export const obtainCurrentSettings = ():SettingsObject  =>{
    return settingsInstance;
}