import { authProvider } from 'src/authProvider';
const obtainToken = async (scopes: string[] = [process.env.REACT_APP_API_SCOPE as string]): Promise<string | undefined> => {
    const userRequest = {
      scopes,
    };
    let tokenAquired: string | undefined = undefined;
    try {
      const response = await authProvider.acquireTokenSilent(userRequest);
      process.env.NODE_ENV === 'development' && console.log('from cache: ' + response);
      tokenAquired = response.accessToken;
    } catch (error) {
      if (error.name === 'InteractionRequiredAuthError') {
        try {
          const response = await authProvider.acquireTokenPopup(userRequest);
          process.env.NODE_ENV === 'development' && console.log('from popup: ' + response);
          tokenAquired = response.accessToken;
        } catch (err) {
          console.log(err);
          return "";
        }
      }
    }
    return tokenAquired;
  };

  export const checkSilently = async (scopes: string[] = [process.env.REACT_APP_API_SCOPE as string]): Promise<string | undefined> => {
    const userRequest = {
      scopes,
    };
    let tokenAquired: string | undefined = undefined;
    try {
      const response = await authProvider.acquireTokenSilent(userRequest);
      process.env.NODE_ENV === 'development' && console.log('from cache: ' + response);
      tokenAquired = response.accessToken;
    } catch (error) {
      return "";
    }
    return tokenAquired;
  };

  export default obtainToken;