import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Typography, List, Paper, Button, ButtonGroup } from '@material-ui/core';
import style from './ArticleSavedScrollSelection.module.scss';
import './ArticleSavedScroll.scss';
import { IContainersQueryShort } from 'src/models/ContainerModel';
import { BasicProjectData } from 'src/views/ProjectSaveView';
import { useTranslation } from 'react-i18next';
import backendUser, { IBackendUserWithSaves } from 'src/interfaces/backendUser';
import userRoles from 'src/enums/userRoles';

interface IProps {
  setSelectedTitle: (a: string) => void;
  selectedTitle: string | undefined;
  titles: string[] | undefined;
  usersAndSaves: IBackendUserWithSaves[];
  selectedUserID: string | undefined;
  setSelectedUserID: React.Dispatch<React.SetStateAction<string | undefined>>
  backendUser: backendUser | null | undefined;

  // headers: IContainersQueryShort[] | null;
  // selectProject: (a: number) => void;
  // selectedProject: BasicProjectData | null;
}

const ArticleSavedScrollSelection = ({ backendUser, usersAndSaves, selectedUserID, setSelectedUserID, titles, selectedTitle, setSelectedTitle }: IProps) => {
  console.log(usersAndSaves);
  return (
    <Paper elevation={2} className={style.imageHolder} >
      {(userRoles.seller <= (backendUser?.role as number)&& usersAndSaves.length>1) &&  (<ButtonGroup orientation="vertical" variant="outlined">
        {usersAndSaves?.map((value, index) => {
          return (
            <Button key={value.id} variant={value.id === selectedUserID ? 'contained' : 'outlined'} onClick={() => { setSelectedUserID(value.id) }}>
              {`${value.firstName}  ${value.lastName}`}
            </Button>
          );
        })}
      </ButtonGroup>)}
      <ButtonGroup orientation="vertical" variant="outlined">
        {titles?.map((value, index) => {
          return (
            <Button key={value || "no title"} variant={value === selectedTitle ? 'contained' : 'outlined'} onClick={() => { setSelectedTitle(value) }}>
              {value || "no title"}
            </Button>
          );
        })}
      </ButtonGroup>
    </Paper>
  )
}

export default ArticleSavedScrollSelection;