import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useLocation, NavLink } from 'react-router-dom';
import { host, storage, inquiry } from 'src/models/urlEndpoints';
import DeserializeToObject from 'src/utilities/DeserializeToObject';
import { InquiryModel, InquiryDetails } from 'src/models/InquiryModel';
import { QuestionsAndAnswears } from 'src/models/ContainerModel';
import style from './Inquiry.module.scss';
import { priceWithFixedDiggits } from 'src/utilities/numbersFormatter';
import ISessionParams from 'src/interfaces/ISessionParams';
import userRoles from 'src/enums/userRoles';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function calculateHeight(ID: InquiryDetails): number {
  return 240 + (220 * ID.Wysokosc) / 605;
}
const questionsArray = [
  'QKolorRamy',
  'QKolorOkladziny',
  'Wariant konstrukcji',
  'Kieszenie transportowe',
  'Izolacja w dachu',
  'Sanitarne - Izolacja w dachu',
  'Izolacja w podlodze',
  'Sanitarne - Izolacja w podlodze',
  'Izolacja w scianach',
  'Sanitarne - Izolacja w panelach',
  'Uwagi dodatkowe',
];

interface IProps extends Partial<ISessionParams> {

}
const Inquiry = (props: IProps) => {
  let { docname, userid } = useParams<{ docname: string; userid: string }>();
  let query = useQuery();
  const [inquiryModel, setInquiryModel] = useState<InquiryModel | undefined>(undefined);
  const [visibleDetails, setVisibleDetails] = useState(false);

  const downloadInquiry = useCallback(async (docname, userid) => {
    // const urlAdress = query.get('url') || '';
    try {
      const resp = await fetch(`${host}/${inquiry(docname, userid)}`);
      const text = await resp.text();
      const inQuiryModel = JSON.parse(text);

      inQuiryModel.fullJson = JSON.parse(inQuiryModel.fullJson);
      inQuiryModel.jsonq = JSON.parse(inQuiryModel.jsonq);
      const transformedInquiry: InquiryModel = DeserializeToObject(new InquiryModel(), inQuiryModel);
      setInquiryModel(transformedInquiry);
      console.log(transformedInquiry);
    } catch (e) {
      console.log(e);
      console.error(e);
    }
  }, []);
  useEffect(() => {
    if (!docname || !userid) {
      return;
    }
    downloadInquiry(docname, userid);
  }, [docname, userid]);

  if (inquiryModel === null) return <p>nieprawidłowe dane</p>;
  if (!inquiryModel) return <p>wczytywanie</p>;
  return (
    <div className={style.main_container}>
      <header className={style.header_container}>
        <img width="200" src={`${window.location.origin}/nag.png`} alt="logo" />
        <span>
          Kosztorys: <b>{inquiryModel.humanReadableCode}</b> {<br />}
          Nazwa projektu: <b> {inquiryModel.projectName} </b>
        </span>
        <span>
          {inquiryModel.userName}
          {'\n'}
          {inquiryModel.fullJson.DaneOsob?.Firma}
          {'\n'}
          {inquiryModel.fullJson.DaneOsob && (
            <div className={style.personalData}>
              <h6>imie: {inquiryModel.fullJson.DaneOsob?.Imie} {' '}
                / nazwisko: {inquiryModel.fullJson.DaneOsob?.Nazwisko} {' '}
                / telefon: {inquiryModel.fullJson.DaneOsob?.Telefon || '-'} {' '}
                / email: {inquiryModel.fullJson.DaneOsob?.Email || '-'}</h6>
            </div>
          )}
        </span>
      </header>
      <section className={style.section}>
        <article className={style.project_image_holder}>

          <h1>Projekt</h1>
          {inquiryModel.links.split('|').map((value, index) => (
            <div key={value} className={style.project_image}>
              <p>kondygnacja {index}</p>
              <img
                height={calculateHeight(inquiryModel.fullJson)}
                src={`${storage}${value}`}
                alt={`kondygnacja ${index}`}
              />
            </div>
          ))}
          <div></div>
        </article>
        <article className={style.tables}>
          <div>
            <h2>Opcje konteneru:</h2>
            <table className={style.tablesOptions}>
              {inquiryModel.jsonq.getQuestionsByArray(questionsArray).map((value, index) => (
                <tr key={value.QI}>
                  <td>{index + 1}</td>
                  <td>{value.QT}</td>
                  <td>{value.AT}</td>
                </tr>
              ))}
            </table>
          </div>
          <div>
            <h2>Opcje konteneru:</h2>
            <table className={style.tablesContainers}>
              <tr>
                <th>nr</th>
                <th>typ</th>
                <th>kondygnacja</th>
                <th>uwagi</th>
                <th>Cena</th>
              </tr>
              {inquiryModel.fullJson.ListaKontenerow?.map((value, index) => (
                <tr key={Math.random().toString()}>
                  <td>{index + 1}</td>
                  <td>
                    {value.Nazwa} <br /> {value.FNazwa}
                  </td>
                  <td>{value.Kondygnacja + 1}</td>
                  <td>
                    {value.ListaModyfikacji?.reduce((accu, next) => {
                      if (next.TypZmiany.substring(0, 14) === 'Dodatkowy opis') {
                        return `${accu} ${next.TypZmiany} : ${next.KosztZmiany} ${(<br />)}`;
                      }
                      return accu;
                    }, '')}
                  </td>
                  <td>{value?.getCenaZeZmianami(inquiryModel.currencyUsed as number, inquiryModel.plnMultiplayer)}</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td colSpan={2}>Obróbki</td>
                <td>
                  {inquiryModel.fullJson.CenaObrobek === inquiryModel.fullJson.ObrobkiDane?.CenaZeZmianami
                    ? ''
                    : 'ceny wymagają sprawdzenia'}
                </td>
                <td>{inquiryModel.fullJson.ObrobkiDane?.getCenaZeZmianami(inquiryModel.currencyUsed as number, inquiryModel.plnMultiplayer)}</td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={2}>Elementy dodatkowe</td>
                <td className={style.additional_description}>
                  <span>
                    {inquiryModel.fullJson.ElementyDodatkowe?.ListaModyfikacji?.map((elem) => {
                      return (
                        <>
                          {<br />} {elem.TypZmiany.split('*')[0]}, ilość: {elem.Ilosc}
                        </>
                      );
                    })}
                  </span>
                </td>
                <td>{inquiryModel.fullJson.ElementyDodatkowe?.getCenaZeZmianami(inquiryModel.currencyUsed as number, inquiryModel.plnMultiplayer)}</td>
              </tr>
              <tr>
                <th className={style.additional_description} colSpan={3}>
                  Razem
                </th>
                <th className={`${style.additional_description} ${style.description_highlight}`} colSpan={2}>
                  {priceWithFixedDiggits(inquiryModel.fullJson.LacznaCena, inquiryModel.currencyUsed as number, inquiryModel.plnMultiplayer, 0, 0)}
                </th>
              </tr>
            </table>
            <p className={style.safety_info}>
              <span>
                Ceny netto, „LOCO Ogorzelice / Dobrzyńska / Kobiałka. <br /> Podana wycena jest wersją roboczą, do potwierdzenia przez Dział Wycen
                Modular System.
              </span>
            </p>
          </div>
        </article>
        <article className={style.save_link}>
          <p>
            <span>
              <b>Adres zapisanego projektu</b>
            </span>
          </p>
          <p>{inquiryModel.fullJson.LinkZapisu}</p>
          <NavLink to={`/model/model?url=${inquiryModel.fullJson.LinkZapisu}`}>
            Szczegóły projektu <br /></NavLink>
          <NavLink to={`/project?adress=${inquiryModel.fullJson.LinkZapisu}`}>
            Wczytaj projekt  <br /></NavLink>
          {props.backendUser && props.backendUser?.idAAD && props.backendUser?.role >= userRoles.seller && (
            <NavLink to={`/project?adress=${inquiryModel.fullJson.LinkZapisu}&override=${inquiryModel.userAAD}`}>
              Wczytaj w imieniu klienta</NavLink>
          )}
        </article>
      </section>
      <footer>
        <div className={style.footer}>
          <div>
            <div />
          </div>
          <p>
            <span>
              MODULAR SYSTEM SP. Z O.O. <br />
              UL. BIELSKA 19 <br />
              09-412 OGORZELICE <br />
              NIP: 7123301642 <br />
            </span>
          </p>
        </div>
        <h5 data-phone={inquiryModel.fullJson.DaneOsob?.Telefon} data-email={inquiryModel.fullJson.DaneOsob?.Email}>
          {inquiryModel.fullJson.DaneOsob?.Firma}
        </h5>
        <button onClick={(e) => setVisibleDetails(!visibleDetails)} id="displayer" type="button">
          Elementy
        </button>
        <p className={style.additional_elements} style={{ display: visibleDetails ? 'block' : 'none' }}>
          {inquiryModel.fullJson.ObrobkiDane?.ListaModyfikacji?.map((elem) => (
            <span>
              {elem.TypZmiany.split('*')[0]} x {elem.Ilosc} <br />
            </span>
          ))}
          {inquiryModel.fullJson.ElementyDodatkowe?.ListaModyfikacji?.map((elem) => (
            <span>
              {elem.TypZmiany.split('*')[0]} x {elem.Ilosc} <br />
            </span>
          ))}
          <a
            href={`${window.location.origin}/model/view?url=${inquiryModel.fullJson.LinkZapisu}`}
            target="_blank"
          >{`${window.location.origin}/model/view?url=${inquiryModel.fullJson.LinkZapisu}`}</a>
        </p>
      </footer>
    </div>
  );
};
export default Inquiry;
