import React from 'react';
import './SectionDouble.scss';

interface IProps {
  children: React.ReactNode;
}

const SectionDouble = (props: IProps) => {
  return <section className="SectionDouble">{props.children}</section>;
};
export default SectionDouble;
