import React from 'react';
import { Paper } from '@material-ui/core';
import instruction from '../../assets/images/instruction.png';

const ContainerImageSchema = () => {
  return (
    <Paper elevation={2} className="schemaContainer">
      <img src={instruction} alt="instrukcja"></img>
    </Paper>
  );
};

export default ContainerImageSchema;
