import React, { useCallback } from 'react';
import MaterialTable, { EditComponentProps, MTableEditField } from 'material-table';
import { pink } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import userRoles from 'src/enums/userRoles';
import { Trans, useTranslation } from 'react-i18next';
import backendUser, { backendUserDTO } from 'src/interfaces/backendUser';

interface ExtendedEditComponentProps extends EditComponentProps<backendUserDTO> {
  memoizedSellers: backendUserDTO[];
}

const EditFieldController = (props: ExtendedEditComponentProps) => {
  const { title } = props.columnDef;
  const { field } = props.columnDef;
  const { memoizedSellers } = props;
  const { value } = props;
  const valueNumber = parseFloat(value);
  //   const userRoleString = userRoles[parseFloat(value)];
  const { t, i18n } = useTranslation();
  const onChangeOverride = useCallback(
    (
      value: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      console.log(value.target.value);
      props.onChange(value.target.value);
    },
    [props.onChange],
  );
  const onSupervisorChange = useCallback(
    (
      value: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      const selectAAID = memoizedSellers.find((elem) => elem.email === value.target.value) 
      props.onChange(selectAAID? selectAAID.email : null);
    },
    [props.onChange, memoizedSellers],
  );
  // console.log(props);

  // props.rowData?.tableData?.editing)

  if (field?.toString().includes('role') && !isNaN(valueNumber)) {
    return (
      <>
        <FormControl className="editFormControl">
          <Select
            className="editCellInput"
            labelId="simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={onChangeOverride}
          >
            <MenuItem value="500">{t(userRoles[userRoles.removed])}</MenuItem>
            <MenuItem value="2000">{t(userRoles[userRoles.custommer])}</MenuItem>
            <MenuItem value="3000">{t(userRoles[userRoles.seller])}</MenuItem>
            <MenuItem value="4000">{t(userRoles[userRoles.administrator])}</MenuItem>
            <MenuItem disabled={true} value="5000">
              {t(userRoles[userRoles.sudo])}
            </MenuItem>
          </Select>
        </FormControl>
      </>
    );
  }
  if (field?.toString().includes('tradeSupervisor')) {
    return (
      <>
        <FormControl className="editFormControl">
          <Select
            className="editCellInput"
            labelId="simple-select-label"
            id="demo-simple-select"
            value={value}
            onChange={onSupervisorChange}
          >
            {memoizedSellers.map((elem, index) => (
              <MenuItem value={elem.email}>{elem.email}</MenuItem>
            ))}
            <MenuItem value="">{t('none')}</MenuItem>
          </Select>
        </FormControl>
      </>
    );
  }
  return <MTableEditField {...props} />;
};

export default EditFieldController;
