import React, { useState, useCallback, useRef, useMemo } from 'react';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import { Column } from 'material-table';

import { red } from '@material-ui/core/colors';
import ISessionParams from 'src/interfaces/ISessionParams';
import userRoles from 'src/enums/userRoles';
import { host, inquiry, inquiryHeader } from 'src/models/urlEndpoints';
import backendUser, { backendUserDTO } from 'src/interfaces/backendUser';
import fetchProtectedJson from 'src/utilities/fetchProtected';
import { InquiryModel, IinquiryHeader } from 'src/models/InquiryModel';
import GenericCrudTable from 'src/views/GenericCrudTable';
import { useParams, useLocation, Redirect, useHistory } from 'react-router-dom';
import { priceWithFixedDiggits } from 'src/utilities/numbersFormatter';

interface IProps extends Partial<ISessionParams> {}
const firstColumnModels = (role: userRoles): Column<object>[] => {
  return [
    {
      title: 'Id',
      field: 'id',
      type: 'numeric',
      editable: 'never',
    },
    {
      title: 'Kod projektu',
      field: 'humanReadableCode',
      editable: 'never',
    },
    {
      title: 'Nazwa',
      field: 'projectName',
      editable: 'never',
    },
    {
      title: 'Cena',
      field: 'price',
      editable: 'never',
    },
    {
      title: 'Data',
      field: 'date',
      editable: 'never',
    },
  ];
};
// projectName

// const queryCertainInquiry = async (): Promise<IinquiryHeader[]> => {
//   const meJson: IinquiryHeader = await fetchProtectedJson(`${host}/${inquiryHeader}`); // /user/myCustomers
//   return [meJson];
// };
interface IinquiryHeaderDTO extends Partial<InquiryModel> {
  id: number;
}


function InquiriesTableController(props: IProps) {
  const displayErrorMessage = useCallback((message: string) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  }, []);
  let history = useHistory();
  const onClick = (event: any, rowData: IinquiryHeaderDTO) => history.push(`/inquiry/${rowData.docName}/${rowData.userAAD}`)
  
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('Start');
  const [title, setTitle] = useState('');
  const [refresh, setRefresh] = useState(Math.random());

  let { id } = useParams(); // email
  const queryCertainInquiry = useMemo(
    () => async (): Promise<IinquiryHeaderDTO[]> => {
      const query = `?user=${id ?? props.backendUser?.email}`;
      const jsonRespponse: InquiryModel[] = await fetchProtectedJson(`${host}/${inquiryHeader}${query}&FullData=true`); // /user/myCustomers
      jsonRespponse.forEach((s: InquiryModel) => {
        s.fullJson = s.fullJson ? JSON.parse((s.fullJson as unknown) as string) : null;
      });

      const resp: IinquiryHeaderDTO[] = jsonRespponse.map((value: InquiryModel, index) => {
        const retobj: IinquiryHeaderDTO = {
          ...value,
          id: index,
          date: new Date(value.dateCreated as string).toLocaleString('pl-pl'),
          price: priceWithFixedDiggits(value.fullJson.LacznaCena, value.currencyUsed as number, value.plnMultiplayer),
        };
        return retobj;
      });
      setTitle(resp[0]?.userName ?? '');
      return resp;
    },
    [id],
  );
  const deleteAction = useMemo(
    () => async (oldData: IinquiryHeaderDTO): Promise<any> => {
      const jsonRespponse: InquiryModel[] = await fetchProtectedJson(
        `${host}/${inquiry(oldData.docName as string, oldData.userAAD as string)}`,
        { method: 'DELETE' },
      ); // /user/myCustomers

      return new Promise((resolve, reject) => {
        if (jsonRespponse) {
          setRefresh(Math.random());
          resolve(null);
        } else {
          reject(null);
        }
      });
    },
    [],
  );

  const materialTableCallback: any = useRef(null);

  const api_regex = /^\/api\/.*/;
  // const currentRole = props?.backendUser?.role as number;
  const isAdmin = (props?.backendUser?.role as number) > userRoles.seller;
  if (api_regex.test(window.location.pathname)) {
    return <div />;
  }

  return (
    <>
      <Snackbar open={snackbarOpen} onClose={() => setSnackbarOpen(false)} autoHideDuration={3500}>
        <SnackbarContent
          style={{
            backgroundColor: red[900],
          }}
          message={snackbarMessage}
        />
      </Snackbar>
      <GenericCrudTable
        isAdmin={isAdmin}
        displayErrorMessage={displayErrorMessage}
        materialTableCallback={materialTableCallback}
        mainQuery={queryCertainInquiry}
        firstColumnModels={firstColumnModels(props?.backendUser?.role as number)}
        title={title}
        deleteAction={deleteAction}
        refresh={refresh}
        onClick={onClick}
      />
    </>
  );
}

export default InquiriesTableController;
