import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { Logger, LogLevel } from 'msal';
// Msal Configurations
const config = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID as string, // 'a8c2d3ba-3ef0-4593-8e9e-e00396fdadb8',
    authority: process.env.REACT_APP_AUTHORITY as string,
    validateAuthority: true,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin + '/login',
    navigateToLoginRequestUrl: true,
  },
  // system: {
  //   logger: new Logger(
  //     (logLevel, message, containsPii) => {
  //       console.log("[MSAL]", message, logLevel, containsPii);
  //     },
  //     {
  //       level: LogLevel.Verbose,
  //       piiLoggingEnabled: false
  //     }
  //   )
  // },
  cache: {
    cacheLocation: 'sessionStorage' as 'sessionStorage' | 'localStorage' | undefined,
    storeAuthStateInCookie: false,
  },
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [
    'user.read',
    // 'user.read.all',
    // 'mail.send',
     process.env.REACT_APP_API_SCOPE as string,
  ],
  authority: process.env.REACT_APP_AUTHORITY as string,
};

// Options
const options = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin + '/auth.html',
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
