import React, { useState, useEffect } from 'react';
import style from './SchemaSelectorCorridorButton.module.scss';

interface IProps {}

const SchemaSelectorCorridorButton = (props: IProps) => {
  return <div className={style.simpleButton}></div>;
};

export default SchemaSelectorCorridorButton;
