import React, { useState, useEffect, useRef, useCallback } from 'react';
// import logo from './logo.svg';
import style from './ProjectSaveView.module.scss';
import SectionDouble from '../components/SectionDouble';
import FirstArticleSaved from 'src/components/firstArticle/FirstArticleSaved';
import SecArticleSaved from 'src/components/secArticle/SecArticleSaved';
import useLogin from 'src/hooks/useLogin';
import RedirectController from 'src/components/RedirectController';
import { IContainersQueryShort, ContainersQuery } from 'src/models/ContainerModel';
import { fetchProtectedJson } from 'src/utilities/fetchProtected';
import { host, storage, save_header, save_project } from 'src/models/urlEndpoints';
import ISessionParams from 'src/interfaces/ISessionParams';

import ArticleSavedScroll from 'src/components/ArticleSavedScroll';

export interface SliderData {
  displayText: string;
  minValue: number;
  maxValue: number;
  value: number;
  setValue?: (val: number) => void;
}

export interface BasicProjectData {
  floorNumber: SliderData;
  containerNumer: SliderData;
  nettoPrice: SliderData;
  imageUrl: string;
  title: string;
  date: string;
  docName: string;
  userAAD: string;
}

interface IProps extends Partial<ISessionParams> {
  children?: React.ReactNode;
}

const MainView = (props: IProps) => {
  const [savesHeaders, setSavesHeaders] = useState<IContainersQueryShort[] | null>(null);
  const [selectedRawSave, setSelectedRawSave] = useState<ContainersQuery | null>(null); // change it onclick
  const [selectedProject, setSelectedProject] = useState<BasicProjectData | null>(null);

  const fetchHeaders = useCallback(async () => {
    const json = await fetchProtectedJson(`${host}/${save_header(props?.backendUser?.email as string)}`);
    if (json) {
      json.reverse();
      setSavesHeaders(json);
    }
  }, []);
  useEffect(() => {
    fetchHeaders();
  }, []);


  useEffect(() => {
    if (!selectedRawSave) {
      console.log('NO QUERY');
      return;
    }

    const floorN: number =
      selectedRawSave.payload.containers.reduce((prev, next) => (prev > next.Floor ? prev : next.Floor), 0) + 1;
    const contNumber: number = selectedRawSave.payload.containers.length;
    const tempVal: BasicProjectData = {
      title: selectedRawSave.title,
      date: selectedRawSave.saveTime,
      docName: selectedRawSave.docName,
      userAAD: selectedRawSave.userAAD,
      floorNumber: {
        displayText: 'floor number',
        minValue: 1,
        maxValue: 4,
        value: floorN,
      },
      containerNumer: {
        displayText: 'containers number',
        minValue: 1,
        maxValue: Math.max(contNumber * 1.1, 200), // dynamic
        value: contNumber,
      },
      nettoPrice: {
        displayText: 'netto price',
        minValue: 0,
        maxValue: 10000000, // dynamic
        value: selectedRawSave.payload.TotalPrice ?? 0,
        // setValue: (val) => changeValue(val, tempVal),
      },
      imageUrl: `${storage}/${selectedRawSave.imageUrl}`,
    };
    setSelectedProject(tempVal);
  }, [selectedRawSave]);

  return (
    <div className={style.main__view}>
      {props.children}
      <svg
        style={{ width: '100%', height: '100%' }}
        className={style.triangleCover}
        viewBox="0 0 1000 1000"
        preserveAspectRatio="none"
      >
        <polygon points="0,0 500,0 1000,1000 0,1000" fill="white" />
      </svg>
      <SectionDouble>
        <>
          <FirstArticleSaved selectedProject={selectedProject} />
          <SecArticleSaved selectedProject={selectedProject} backendUser={props.backendUser}/>
        </>
      </SectionDouble>
      <ArticleSavedScroll backendUser={props.backendUser} selectedProject={selectedProject} setSelectedRawSave={setSelectedRawSave} headers={savesHeaders} />
    </div>
  );
};

export default MainView;
