import React from 'react';
import './FooterBasic.scss';
import { useTranslation } from 'react-i18next';
// import { ReactComponent as LogoModular } from '../../assets/images/MODULAR_logo.svg';
import { ReactComponent as LogoModular } from '../assets/images/MODULAR_logo.svg';
const FooterBasic = () => {
  const { t } = useTranslation();
  return (
    <footer className="main_footer">
      <div>
        <a href="">{t('privacy policy')}</a>
        <a href="tel:+48 24 366 79 00">+48 24 366 79 00</a>
        <a href="mailto:biuro@modularsystem.pl">BIURO@MODULARSYSTEM.PL </a>
      </div>
      <LogoModular
        style={{
          maxHeight: '80%',
          alignSelf: 'center',
          height: '10vmin',
        }}
        color="white"
      />
      <div>
        <p>Modular System sp. z o.o.</p>
        <p>ul. Bielska 19</p>
        <p>09-412 Ogorzelice</p>
        <p>©2017-2020 CYPYRIGHT: MODULAR SYSTEM SP. Z O.O.</p>
      </div>
    </footer>
  );
};

export default FooterBasic;
