export const host = process.env.REACT_APP_DYNAMIC_HOST as string;

export const storage = process.env.REACT_APP_STORAGE as string;

/** GET PUT */
export const userEndpoint = 'user';
/** GET */
export const usersAll = 'user/all';
/** GET */
export const usersMy = 'user/myCustomers';
/** GET PATCH */
export const userUuId = (uuid: string) =>`user/${uuid}`
/** PATCH */
export const userUuIdRoleID = (uuid: string, roleID: string) =>`user/${uuid}/${roleID}`

/** GET */
export const setupDatas = (setupType: string) => `setup_data/${setupType}`;
/** GET PUT */
export const setupDatasAll = (setupType: string) => `setup_data/all/${setupType}`;

export const inquiryHeader = "inquiry/headers";
export const inquiry = (docname: string, userid: string) => `inquiry/${docname}/${userid}`;

/** GET */
export const save_header = (userEmail: string) => `project/saves_headers/?user=${userEmail}`;
export const save_project = (docname: string, userid: string) => `project/save/${docname}/${userid}`;