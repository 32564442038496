import React, { useCallback } from 'react';
import MaterialTable, {
  EditComponentProps,
  MTableEditField,
} from 'material-table';
import { pink } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

enum UsageType {
  notExist,
  inherits,
  inheritsMobilbox,
  withValue,
  hideValue,
}

const EditFieldController = (props: EditComponentProps<any>) => {
  const { title } = props.columnDef;
  const { value } = props;
  const valueNumber = parseFloat(value);

  const onChangeOverride = useCallback(
    (
      value: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
    ) => {
      const targetValue =
        value.target.value === UsageType.notExist
          ? 0
          : value.target.value === UsageType.inherits
          ? -1
          : value.target.value === UsageType.inheritsMobilbox
          ? -2
          : 50000;
      props.onChange(targetValue);
    },
    [props.onChange],
  );
  // props.rowData?.tableData?.editing)
  const checkValue: UsageType =
    !value || valueNumber === 0
      ? UsageType.notExist
      : valueNumber === -1
      ? UsageType.inherits
      : valueNumber === -2
      ? UsageType.inheritsMobilbox
      : UsageType.hideValue;
  if (title?.toString().includes('Cena') && props.rowData?.id > 0) {
    return (
      <>
        <FormControl className="editFormControl">
          {/* <InputLabel
            className="editCellInput"
            id="simple-select-label"
            variant="standard"
          >
            typ
          </InputLabel> */}
          <Select
            className="editCellInput"
            labelId="simple-select-label"
            id="demo-simple-select"
            value={checkValue}
            onChange={onChangeOverride}
          >
            <MenuItem value={UsageType.notExist}>niedostępny</MenuItem>
            {title?.toString() === 'Cena' && (
              <MenuItem value={UsageType.inherits}>Cena Podstawowa</MenuItem>
            )}
            {title?.toString() === 'Cena' && (
              <MenuItem value={UsageType.inheritsMobilbox}>
                Cena Mobilbox
              </MenuItem>
            )}
            <MenuItem value={UsageType.withValue}>dowolna wartość</MenuItem>
          </Select>
        </FormControl>
        {checkValue === UsageType.hideValue && <MTableEditField {...props} />}
      </>
    );
  } else if (
    props.rowData?.nazwa &&
    title?.toString().includes('parametr') &&
    props.rowData?.nazwa.includes('[boolean]')
  ) {
    const columnDef = { ...props.columnDef, type: 'boolean' };
    return <MTableEditField {...props} columnDef={columnDef} />;
  }
  return <MTableEditField {...props} />;
};

export default EditFieldController;
