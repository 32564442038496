import React from 'react';
import style from './ConfiguratorHeader.module.scss';
interface IProps {}

const ConfiguratorHeader = (props: IProps) => {
  return (
    <div className={style.labelsHolder}>
      <h1>Kreator</h1>
      <h2>Projektu</h2>
    </div>
  );
};

export default ConfiguratorHeader;
